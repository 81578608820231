import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import Init from '../components/Init.vue'
import SurveyList from '../components/SurveyList.vue'
import User from "../components/User.vue";
import register from "../components/register.vue"
import GDS from "../components/scale/GDS.vue"
import AS from "../components/scale/AS.vue"
import ABS from "../components/scale/ABS.vue"
import ADCS from "../components/scale/ADCS.vue"
import CDR from "../components/scale/CDR.vue"
import FAB from "../components/scale/FAB.vue"
import MMSE from "../components/scale/MMSE+HDS-R.vue"
import MoCA from "../components/scale/MoCA.vue"
import TableReport from "../components/TableReport.vue"

Vue.use(Router)

export default new Router ({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: "login"
        }, {
            path: '/init',
            component: Init
        }, {
            path: '/register',
            component: register
        }, {
            path: '/login',
            component: Login
        }, {
            path: '/report/:type',
            component: TableReport
        }, {
            path: "/questionnaire",
            component: SurveyList,
        }, {
            path: '/personalCenter',
            component: User
        }, {
            path: '/questionnaire/GDS',
            component: GDS,
        }, {
            path: '/questionnaire/apathy%20score',
            component: AS,
        }, {
            path: '/questionnaire/ABS',
            component: ABS
        }, {
            path: '/questionnaire/ADCS-ADL',
            component: ADCS
        }, {
            path: '/questionnaire/CDR',
            component: CDR
        }, {
            path: '/questionnaire/FAB',
            component: FAB
        }, {
            path: '/questionnaire/MMSE+HDS-R',
            component: MMSE
        }, {
            path: '/questionnaire/MoCA',
            component: MoCA
        }, 
    ]
})
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}
