<template>
    <div style="margin-left: auto; margin-right: auto; ">
        <el-row>
            <el-col :xs="24" :sm="{span:22, offset:1}" :md="{span:16, offset:4}">
                <el-button type="primary" icon="el-icon-arrow-left" @click="toHome" style="text-decoration:none; float:left; margin-top: 1rem;">戻る</el-button>
                <div class="header" style="margin-top: 2rem"><span style="display: inline;">{{questionnaire.title}}</span></div>
                <div style="margin-top: 0.3rem;"><span style="display: inline;">{{questionnaire.mentor}}</span></div>
                <div v-if="width > 635">
                    <el-form :inline="true" class="demo-form-inline" style="margin-top: 3rem; float: left;" >
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <el-form :label-position="labelPosition" style="margin-top: 3rem; float: left; margin-left: 1rem;" class="formLeft">
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table
                    :data="res"
                    :span-method="objectSpanMethod"
                    border
                    :max-height=height-150
                    :header-cell-style="{'text-align':'center'}"
                    style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                    <el-table-column width="80" align="center">
                        <template slot-scope="scope">
                            {{res[scope.$index].group_title}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="質問内容" width="120"></el-table-column>
                    <el-table-column  label="0（健康）">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="0" style="padding-right: 5px">{{res[scope.$index].options[0]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="0.5（疑い）">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="1" style="padding-right: 5px">{{res[scope.$index].options[1]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="1（軽度）">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="2" style="padding-right: 5px">{{res[scope.$index].options[2]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="2（中等度）">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="3" style="padding-right: 5px">{{res[scope.$index].options[3]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="3（重度）">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="4" style="padding-right: 5px">{{res[scope.$index].options[4]}}</el-radio>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-loading="loading" style="margin-top: 3rem; margin-bottom: 3rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document">確 定</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            width: document.documentElement.clientWidth,
            tableData: [],
            userAnswer: [],
            questionnaire: {},
            loading: false,
            name: '',
            username: '',
            patientName: '',
            patientID: '',
            labelPosition: "left",
            res: [],
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.width = document.documentElement.clientWidth
            this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 1) {
                    return {
                        rowspan: 5,
                        colspan: 1
                    }
                } else if (rowIndex === 0) {
                    return {
                        rowspan: 1,
                        colspan: 1
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                }
            }
            if (rowIndex === 5) {
                if (columnIndex === 2) {
                    return [1, 2];
                } else if (columnIndex === 3){
                    return [0, 0];
                }
            }
        },
        toHome() {
            this.$router.push({path: '/questionnaire'})
        },
        async submitData() {
            this.loading = true
            console.log("gethere")
            let userAnswer = []
            for (let i = 0; i < 2; i++) {
                userAnswer.push([])
            }
            for (let i = 0; i < 6; i++) {
                if (i == 0) {
                    userAnswer[i].push(this.userAnswer[0][i])
                } else {
                    userAnswer[1].push(this.userAnswer[0][i])
                }
            }
            for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
                for (let j = 0; j < userAnswer[i].length; j++) {
                    if (userAnswer[i][j].choiceAnswer === -1) {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                }
            }
            try {
                let res = await axios({
                    method: 'post',
                    url: window.serverURL + 'answer',
                    data: {
                        Name: this.name,
                        UserName: this.username,
                        SurveyName: this.questionnaire.title,
                        SurveyType: this.questionnaire.type,
                        SurveyData: userAnswer,
                        PatientName: this.patientName,
                        PatientID: this.patientID,
                    },
                })
                this.$message({
                    message: 'データの送信が成功しました！',
                    type: 'success'
                })
                var value = Object.values(res.data.data)
                var key = Object.keys(res.data.data)
                for (let i = 0; i < key.length; i++) {
                    this.$set(this.$store.state.pdfdata, key[i], value[i])
                }
                localStorage.setItem('userAnswer', JSON.stringify(userAnswer))
                localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                this.$router.push({path:'/report/' + this.questionnaire.type})
            } catch (e) {
                this.loading = false
                this.$message({
                    message: 'データの送信に失敗しました。もう一度送信してください。',
                    type: 'error'
                })
            }
        },
    },
    async created() {
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        try {
            let res = await axios.get(window.serverURL + "question?name=CDR")
            this.res = res.data.data
            for (let i = 0; i < this.res.length; i++) {
                this.res[i].options = JSON.parse(this.res[i].options)
                this.$set(this.questionnaire,"id",this.res[0].id)
                this.$set(this.questionnaire,"type",this.res[0].type)
                this.$set(this.questionnaire,"title",this.res[0].title)
                this.$set(this.questionnaire,"mentor",this.res[0].mentor)
                this.$set(this.questionnaire,"mentorJudge",this.res[0].mentorJudge)
                this.questionnaire.groupQuestions = []
                var l = this.res.length
                var grouplength = this.res[l-1].group + 1
                for (let j = 0; j < grouplength; j++ ) {
                    var groupQuestion = {}
                    groupQuestion.group = j
                    for (let t = 0; t < this.res.length; t++) {
                        var g = this.res[t].group
                        if (groupQuestion.group == g) {
                            groupQuestion.title = this.res[t].group_title
                        }
                    }
                    this.questionnaire.groupQuestions.push(groupQuestion)
                    this.questionnaire.groupQuestions[j].messages = []
                    for (let k = 0; k < this.res.length; k++) {
                        if (this.res[k].group == this.questionnaire.groupQuestions[j].group) {
                            var message = {}
                            message.id = this.res[k].question_id
                            message.type = this.res[k].question_type
                            message.content = this.res[k].content
                            if (this.res[k].tableheader.length != 0) {
                                message.headers = JSON.parse(this.res[k].tableheader)
                            }
                            message.supplement = this.res[k].supplement
                            if (this.res[k].options.length != 0) {
                                message.options = this.res[k].options
                            }
                            this.questionnaire.groupQuestions[j].messages.push(message)
                        }
                    }
                }
            }
            this.loading = false
        } catch (e) {
            this.loading = false
            this.$message({
                message: '評価スケール取得に失敗しました。更新して再試行してください。',
                type: 'error'
            })
        }
        for (let i = 0; i < this.res.length; i++) {
            this.userAnswer.push([])
            this.userAnswer[0].push({answerType: this.res[i].question_type, choiceAnswer: -1, listAnswer: [], tableData: [], time:"", date:"", supplement: ""})
        }
        for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
            this.tableData.push(this.questionnaire.groupQuestions[i].messages)
        }
        localStorage.setItem('questionnaire', JSON.stringify(this.questionnaire))
        localStorage.setItem('tableData', JSON.stringify(this.tableData))
    },
}
</script>

<style scoped>
.radio >>> .el-radio__label {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
}
 @media screen and (max-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 10px;
    }
}

@media screen and (min-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 30px;
    }
}
.demo-form-inline >>> .el-form-item__label {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.formLeft >>> .el-form-item__label {
    font-size: 20px;
}
.formLeft >>> .el-form-item {
    margin-right: 30px;
}
</style>