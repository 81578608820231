import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import axios from 'axios'
import '../theme/index.css'
import qs from 'qs'
import router from './router/index.js'
import QS from 'qs'
import Vuex from 'vuex'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ja'

Vue.use(ElementUI, { locale })

Vue.use(Vuex);
Vue.prototype.qs = QS;
Vue.use(ElementUI);
Vue.prototype.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.copyrequestbody = true;

const store = new Vuex.Store({
    state: {
        needLogin: false,
        dialogVisible: false,
        username: '',
        name: '',
        patientList: [],
        token: '',
        AnswerList: [],
        pdfdata: {},
        questionnaire: {}
    },
    mutations: {
        afterLogin(state,username,role) {
            state.needLogin = false
            state.username = username
        },
        needLogin(state) {
            state.needLogin = true
        },
        afterLog(state) {
            state.dialogVisible = false
        },
        logOut(state){
            localStorage.removeItem('token');
            state.token = null;
            localStorage.removeItem('name');
            localStorage.removeItem('username');
        },
        needLog(state) {
            state.dialogVisible = true
        },
        setToken (state,token) {
            state.token = token;
            localStorage.setItem("token", token);     //存储token
        },
        getQuestionnarie(state, questionnaire) {
            state.questionnaire = questionnaire;
        },
    } 
})
//const serverURL =  'http://localhost:38870/'
const serverURL =  'https://api.qpj.bjbigiq.com/'
window.serverURL = serverURL

new Vue({
    el: '#app',
    store,
    components: { App },
    router,
    render: h => h(App)
});
  
Vue.prototype.accountLogin = async function (userName, passWord, posturl, PhoneUnique) {
    if (this.userName === '' || this.passWord === '') {
        this.$message({
            message: '正しい情報を入力下さい。',
            type: 'warning'
        });
        return
    }
    this.loading = true
    try {
        var res = await axios.post(window.serverURL + posturl, qs.stringify({
            username: userName,
            password: passWord,
        }))
        localStorage.setItem('username', userName)
        localStorage.setItem('name', res.data.data.name)
        this.loading = false
        this.$store.commit('setToken', getCookie('token'))
        this.$router.push('/questionnaire')
    } catch (err) {
        this.loading = false
        this.$message.error('アカウントまたはパスワードが正しくありません')
    }
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++)
    {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
}

 Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        }
    }
    return storage.setItem(key, data);
}

Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'watchStorage') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
  }