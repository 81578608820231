<template>
    <div id=" Init" style="background-color: #ffffff">
        <div style="height: 5rem"></div>
        <el-container>
            <el-main>
                <el-card shadow="hover" style="height: 28rem; width: 24rem; margin: auto;" :body-style="{padding: '2rem'}">
                    <el-form>
                        <div style="height: 0.5rem"></div>
                        <div class="title" style="height: 3rem; font-weight: bold;" >{{"評価スケールプラットフォーム"}}</div>
                        <div style="height: 0.2rem"></div>
                        <el-form-item>
                            <el-input v-model="name" placeholder="请输入您的姓名"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="userName" placeholder="请输入账户名" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="passWord" show-password placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-radio v-model="identity" label="1">用户</el-radio>
                            <el-radio v-model="identity" label="2">医生</el-radio>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="init">初始化</el-button>
                        </el-form-item>
                        <div style="height: 0.5rem"></div>
                        <div class="notes">{{"注：服务器不会明文储存账户信息，因此请妥善保存您的账户密码。"}}</div>
                    </el-form>
                </el-card>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import axios from 'axios'
    import qs from 'qs'
    export default {
        data() {
            return {
                userName: '',
                name: '',
                passWord: '',
                identity: ''
            }
        },
        methods: {
            async init() {
                if (this.userName === '' || this.passWord === '' || this.name === '' || this.identity === '') {
                    this.$message({
                        message: '输入内容不可为空',
                        type: 'warning'
                    });
                    return
                }
                try {
                    await axios.post(window.serverURL + 'init', qs.stringify({
                        username: this.userName,
                        name: this.name,
                        password: this.passWord,
                        role: this.identity
                    }))
                    if (this.identity == "1"){
                        this.accountLogin(this.userName, this.passWord, "account/login", true)
                    } else {
                        this.accountLogin(this.userName, this.passWord, "account/login", true)
                    }
                } catch (e) {
                    this.$message({
                        message: '注册信息提交失败，请再次尝试提交。',
                        type: 'error'
                    })
                }
            },
        },
        name: "Init",
    }
</script>

<style scoped>
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }

</style>
