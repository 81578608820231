<template>
    <div id=" Init" style="background-color: #ffffff; height: 100%">
        <div style="height: 5rem"></div>
        <el-container>
            <el-main>
                <el-row>
                    <el-col :sm="{span:16, offset:4}" :md="{span:12, offset:10}">
                        <el-card shadow="hover" style="height: 35rem; margin: auto;" :body-style="{padding: '2rem'}" class="registerbox">
                            <el-form :model="user" :rules="rules" ref="user">
                                <div style="height: 0.5rem"></div>
                                <div class="title" style="height: 3rem; font-weight: bold;" >{{"新規登録"}}</div>
                                <div style="height: 0.2rem"></div>
                                <el-form-item prop="name">
                                    <el-input v-model="user.name" placeholder="名前を入力してください"></el-input>
                                </el-form-item>
                                <el-form-item prop="userName">
                                    <el-input v-model="user.userName" placeholder="ユーザー名を入力してください" clearable></el-input>
                                </el-form-item>
                                <el-form-item prop="passWord">
                                    <el-input v-model="user.passWord" show-password placeholder="パスワードを入力してください"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('user')">新規登録</el-button>
                                </el-form-item>
                                <div style="height: 0.5rem"></div>
                                <div class="notes">{{"お客様が我々のサービス等をご利用になる際、お客様の個人データ及びプライバシーは、安全に守られています。パスワードを大切に保管してください。パスワードを忘れたら、管理者に連絡してください。"}}</div>
                            </el-form>
                        </el-card>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
    export default {
        data() {
            var vUsername = (rule,value,callback) => {
                if (!value){
                    callback(new Error('正しい情報を入力下さい。'));
                } else if (value.length < 2 || value.length > 10 ){
                    callback(new Error('2〜10文字の長さ'));
                } else {
                    axios.post(window.serverURL + 'uniqueJudge', {
                        username: value,
                    }).then(function(res){
                        if (res.data.data.UserNameUnique != true){
                            callback(new Error('このユーザー名は利用できません。他のユーザー名を指定してください。'));
                        }else {
                            callback()
                        }
                    })
                }
            }
            return {
                PhoneUnique: true,
                user: {
                    userName: '',
                    name: '',
                    passWord: '',
                },
                rules: {
                    userName: [
                        { validator: vUsername, required: true, trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '名前を入力してください', trigger: 'blur' },
                        { min: 2, max: 10, message: '2〜10文字の長さ', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: 'パスワードを入力してください', trigger: 'blur'}
                    ],
                },
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.init()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async init() {
                try {
                    await axios.post(window.serverURL + 'init', qs.stringify({
                        username: this.user.userName,
                        name: this.user.name,
                        password: this.user.passWord,
                    }))
                    localStorage.setItem('username', this.user.userName)
                    localStorage.setItem('name', this.user.name)
                    this.loading = false
                    this.$store.commit('setToken', this.getCookie('token'))
                    this.$router.push('/login')
                } catch (error) {
                    console.log(error);
                    this.$message({
                        message: '登録情報の送信に失敗しました。もう一度送信してください。',
                        type: 'error'
                    })
                }
            },
            async firstLogin(PhoneUnique) {
                try {
                    var res = await axios.post(window.serverURL + "account/login", qs.stringify({
                        username: this.user.userName,
                        password: this.user.passWord,
                    }))
                    localStorage.setItem('username', this.user.userName)
                    localStorage.setItem('name', this.user.name)
                    this.$store.commit('afterLogin', this.user.userName, res.data.data.role)
                    this.loading = false
                    this.$store.commit('setToken', this.getCookie('token'))
                } catch (err) {
                    this.loading = false
                    this.$message.error('アカウントまたはパスワードが正しくありません')
                }
            },
            getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for(var i=0; i<ca.length; i++){
                    var c = ca[i].trim();
                    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
                }
                return "";
            }, 
        },
        name: "Init",
    }
</script>

<style scoped>
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }
    @media screen and (max-width: 768px) {
   .registerbox{
        width: 18rem;
    }
    }
    @media screen and (min-width: 768px) {
    .registerbox{
        width: 22rem;
    }
    }
</style>
