<template>
    <div>
        <el-main :style="mainStyle" style="background-color: #efefef; padding: 0; margin: 0;">
            <el-header height="4rem" style="background-color: #4682B4; position: fixed; width: 100%; z-index: 3;">
                <div style="height:0.6rem;"></div>
                <div><span class="header" style="display: inline; color: #fff; font-weight: 600;">センター</span></div>
            </el-header>
            <el-row>
                <el-col :xs="{span:22, offset:1}" :sm="{span:16, offset:4}" :md="{span:12, offset:6}">
                    <el-row style="line-height: 60px;">
                        <el-col :xs="24" :sm="18" :md="10" style="font-size: 30px; color: rgba(0,0,0,0.98); margin-top: 8rem; text-align: left; ">
                            <router-link :to="'/questionnaire'" class="el-icon-back" style="text-decoration:none; color: #000000">評価スケール</router-link>
                        </el-col>
                    </el-row>
                    <el-card style="margin-top: 0.3rem; margin-left: auto; margin-right: auto">
                        <el-form :label-position="labelPosition" label-width="100px" :model="user" ref="user" :rules="rules1" style="text-align:left; width:70%; margin-top: 3rem; margin-left: auto; margin-right: auto">
                            <el-form-item label="ユーザ名" prop="username">
                                <el-input v-model="user.username"></el-input>
                            </el-form-item>
                            <el-form-item label="名前" prop="name">
                                <el-input v-model="user.name"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-row style="width:70%; line-height: 20px; margin-top:1rem; margin-bottom:3rem; margin-left: auto; margin-right: auto;">
                            <el-col style="color: rgba(0,0,0,0.98)" :span="12">
                                <el-button type="primary" @click="submitForm('user')" style="float: left;">完了</el-button>
                            </el-col>
                            <el-col style="color: rgba(0,0,0,0.98);" :span="12">
                                <el-button @click="jump" style="float: right;">キャンセル</el-button>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            var vUsername = (rule,value,callback) => {
                this.UserNameUnique = true
                if (value != this.rawData.username){
                    if (!value){
                        callback(new Error('正しい情報を入力下さい。'));
                    } else if (value.length < 2 || value.length > 10 ){
                        callback(new Error('2〜10文字の長さ'));
                    } else {
                        axios.post(window.serverURL + 'uniqueJudge', {
                            username: value,
                        }).then(function(res){
                            if (res.data.data.UserNameUnique != true){
                                callback(new Error('このユーザー名は利用できません。他のユーザー名を指定してください。'));
                            }else {
                                callback()
                            }
                        })
                    }
                } else {
                    callback()
                }
            }
            return {
                buttonDisable:[],
                pageSize: 5,
                pageSizes: [5,10,15,20],
                currentPage: 1,
                totalCount: 0,
                tabPosition: 'right',
                pdfData:[],
                mainStyle: {height: window.innerHeight / 16 + 'rem'},
                labelPosition: 'top',
                loading: false,
                rawData: {},//从数据库获得的原始数据
                diffData: {},
                user: {
                    username: '',
                    name: '',
                },
                dialogFormVisible: false,
                rules1: {
                    username: [
                        { validator: vUsername, required: true, trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '名前を入力してください', trigger: 'blur' },
                        { min: 2, max: 10, message: '2〜10文字の長さ', trigger: 'blur' }
                    ],
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            handleSizeChange(val) {//每次切换页面大小时，此方法响应，改变页面大小
                this.pageSize = val;
                this.currentPage = 1;//改变页面大小后，跳转回第一页
                this.searchPDFs()
            },
            handleCurrentChange(val) {//每次切换当前页时，此方法响应，改变当前页
                this.currentPage = val;
                this.searchPDFs()
            },
            handleClick(tab) {
                if(tab.label == "臨床資料"){
                    this.searchPDFs()
                }
            },
            async searchPDFs() {
                this.buttonDisable = []
                try {
                    let res = await axios.get(window.serverURL + "searchPDFs/patient",{
                        params: {
                            page: this.currentPage,
                            pageSize: this.pageSize,
                        }
                    })
                    this.pdfData = res.data.data.answerlists
                    this.totalCount = res.data.data.totalPage
                    for (let i = 0; i < this.pdfData.length; i++) {
                        var disable = true
                        this.pdfData[i].CreatedAt = this.pdfData[i].CreatedAt.substring(0, 10).replace("T", " ")
                        if (this.pdfData[i].QuestionnaireType == "CTQ" || this.pdfData[i].QuestionnaireType == "CERQ" || this.pdfData[i].QuestionnaireType == "CAQ" || this.pdfData[i].QuestionnaireType == "LES" || this.pdfData[i].QuestionnaireType == "PHQ-9" || this.pdfData[i].QuestionnaireType == "PSQI" || this.pdfData[i].QuestionnaireType == "SDS" || this.pdfData[i].QuestionnaireType == "SAS&SDS" || this.pdfData[i].QuestionnaireType == "PANAS" || this.pdfData[i].QuestionnaireType == "SCL-90" || this.pdfData[i].QuestionnaireType == "DARS" || this.pdfData[i].QuestionnaireType == "HAMD" || this.pdfData[i].QuestionnaireType == "PDQ-D" || this.pdfData[i].QuestionnaireType == "MHQ" || this.pdfData[i].QuestionnaireType == "Rutter" ){
                            disable = false
                        }
                        this.buttonDisable.push(disable)
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: 'レポートリストを取得できませんでした。更新して再試行してください。',
                        type: 'warning'
                    })
                }
            },
            async downloadpdf(row) {//根据报告id来查询
                try {
                    let res = await axios.get(window.serverURL + 'downloadPDF/patient/' + row.ID)
                    if (res.data.data.TableData) {
                        var TableHeader = []
                        for (let i = 0; i < res.data.data.TableData[0].length; i++) {
                            var header = {}
                            header.prop = "prop"+ i 
                            header.propName = res.data.data.TableData[0][i]
                            TableHeader.push(header)
                        }   
                        var pdfTable = []
                        for (let i = 1; i < res.data.data.TableData.length; i++) {//从TableData数组的第二个元素开始，是表格内的数据
                            var propObject = {}
                            for (let j = 0; j < res.data.data.TableData[0].length; j++) {
                                this.$set(propObject, "prop"+ j, res.data.data.TableData[i][j])
                            }
                            pdfTable.push(propObject) 
                        }
                        res.data.data.TableData = pdfTable
                        this.$set(res.data.data, "TableHeader", TableHeader)
                    }
                    var value = Object.values(res.data.data)
                    var key = Object.keys(res.data.data)
                    for (let i = 0; i < key.length; i++) {
                        this.$set(this.$store.state.pdfdata, key[i], value[i])
                    }
                    localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                    this.$router.push({path:'/report/' + row.QuestionnaireType})
                } catch(e) {
                    this.loading = false
                    this.$message({
                        message: '分析レポートを取得できませんでした。更新して再試行してください。',
                        type: 'warning'
                    })
                }
            },
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 + 'rem';
            },
            jump() {
                this.$router.push({path:'/questionnaire'})
            },
            async updateUser(){
                let ifChange = false
                for (var k in this.rawData) {
                    if (this.rawData.hasOwnProperty(k)&& this.user.hasOwnProperty(k)) {
                        if (this.rawData[k] !== this.user[k]) {
                            if (!this.diffData) {
                                this.diffData = {};
                                ifChange = false
                            }
                            this.diffData[k] = this.user[k];
                            ifChange = true
                        }
                    }
                }
                if (ifChange == true){
                    try {
                        await axios.put(window.serverURL + 'account/doctor', {
                            Name: this.diffData.name,
                            Username: this.diffData.username
                        })
                        this.$message({
                            message: '個人情報の修正に成功しました！',
                            type: 'success'
                        })
                        this.loading = false
                        this.getUser()
                    } catch (e) {
                        this.loading = false
                        this.$message({
                            message: '個人情報の変更に失敗しましたので、もう一度送信してください。',
                            type: 'error'
                        })
                    }
                } 
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.updateUser()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getUser(){
                try {
                    let res = await axios.get(window.serverURL + 'account/doctor')
                    if (res.data.length !== 0) {
                        this.rawData = { ...res.data.data}
                        this.user = res.data.data
                        this.$addStorageEvent("username", this.user.username);
                    }
                } catch (e) {
                    this.loading = false
                    this.$message({
                        message: '個人情報を取得できませんでした。更新してもう一度お試しください。',
                        type: 'error'
                    })
                }
            },
        },
        created() {
            this.name = localStorage.getItem('name')
            this.username = localStorage.getItem('username')
            if (this.$route.path !== '/login'|| this.$route.path !== '/register') {
                this.loading = true
                axios.get(window.serverURL + 'verify')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.getUser()
        }
    }
</script>

<style>
@media screen and (max-width: 769px) {
    .Pagination {
        display: none;
    }
    .header{
        font-size: 23px; 
    }
    .el-icon-back{
        font-size: 23px;
    }
}
@media screen and (min-width: 769px) {
    .smallPagination {
        display: none;
    }
     .header{
        font-size: 30px;
    }
    .el-icon-back{
        font-size: 30px;
    }
}
</style>