<template>
    <div style="margin-left: auto; margin-right: auto; ">
        <el-row>
            <el-col :xs="{span:22, offset:1}" :sm="{span:20, offset:2}" :md="{span:16, offset:4}">
                <el-button type="primary" icon="el-icon-arrow-left" @click="toHome" style="text-decoration:none; float:left; margin-top: 1rem;">戻る</el-button>
                <!-- <router-link :to="'/questionnaire'" class="el-icon-back" style="text-decoration:none; color: #000000; float:left; margin-top: 1rem;">戻る</router-link> -->
                <div class="header" style="margin-top: 2rem"><span style="display: inline;">{{questionnaire.title}}</span></div>
                <div style="margin-top: 0.3rem;"><span style="display: inline;">{{questionnaire.mentor}}</span></div>
                <div v-if="width > 635">
                    <el-form :inline="true" class="demo-form-inline" style="margin-top: 3rem; float: left;" >
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <el-form :label-position="labelPosition" style="margin-top: 3rem; float: left; margin-left: 1rem;" class="formLeft">
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table
                    :data="tableData"
                    :max-height=height-150
                    border
                    :header-cell-style="{'text-align':'center'}"
                    style="max-width: 100%; margin-left: auto; margin-right: auto; font-size: 20px">
                    <el-table-column prop="id" label="番号" min-width="45" align="center"></el-table-column>
                    <el-table-column prop="content" label="質問内容"></el-table-column>
                    <el-table-column align="center" label="ほとんどない">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="0" border>{{questionnaire.groupQuestions[0].messages[scope.$index].options[0]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column  align="center" label="たまにある">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="1" border>{{questionnaire.groupQuestions[0].messages[scope.$index].options[1]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="時々ある">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="2" border>{{questionnaire.groupQuestions[0].messages[scope.$index].options[2]}}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="しょっちゅうある">
                        <template slot-scope="scope">
                            <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :label="3" border>{{questionnaire.groupQuestions[0].messages[scope.$index].options[3]}} </el-radio>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-loading="loading" style="margin-top: 3rem; margin-bottom: 3rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document">確 定</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            width: document.documentElement.clientWidth,
            tableData: [],
            userAnswer: [],
            questionnaire: {},
            loading: false,
            name: '',
            username: '',
            patientName: '',
            patientID: '',
            labelPosition: "left"
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.width = document.documentElement.clientWidth
            this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        },
        toHome() {
            this.$router.push({path: '/questionnaire'})
        },
        async submitData() {
            this.loading = true
            console.log("gethere")
            for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
                for (let j = 0; j < this.userAnswer[i].length; j++) {
                    if (this.userAnswer[i][j].choiceAnswer === -1) {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                }
            }
            try {
                let res = await axios({
                    method: 'post',
                    url: window.serverURL + 'answer',
                    data: {
                        Name: this.name,
                        UserName: this.username,
                        SurveyName: this.questionnaire.title,
                        SurveyType: this.questionnaire.type,
                        SurveyData: this.userAnswer,
                        PatientName: this.patientName,
                        PatientID: this.patientID,
                    },
                })
                this.$message({
                    message: 'データの送信が成功しました！',
                    type: 'success'
                })
                var value = Object.values(res.data.data)
                var key = Object.keys(res.data.data)
                for (let i = 0; i < key.length; i++) {
                    this.$set(this.$store.state.pdfdata, key[i], value[i])
                }
                localStorage.setItem('userAnswer', JSON.stringify(this.userAnswer))
                localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                this.$router.push({path:'/report/' + this.questionnaire.type})
            } catch (e) {
                this.loading = false
                this.$message({
                    message: 'データの送信に失敗しました。もう一度送信してください。',
                    type: 'error'
                })
            }
        },
    },
    async created() {
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        try {
            let res = await axios.get(window.serverURL + "question?name=ABS")
            var temp = res.data.data
            for (let i = 0; i < temp.length; i++) {
                this.questionnaire = {}
                this.$set(this.questionnaire,"id",temp[0].id)
                this.$set(this.questionnaire,"type",temp[0].type)
                this.$set(this.questionnaire,"title",temp[0].title)
                this.$set(this.questionnaire,"mentor",temp[0].mentor)
                this.$set(this.questionnaire,"mentorJudge",temp[0].mentorJudge)
                this.questionnaire.groupQuestions = []
                var l = temp.length
                var grouplength = temp[l-1].group + 1
                for (let j = 0; j < grouplength; j++ ) {
                    var groupQuestion = {}
                    groupQuestion.group = j
                    for (let t = 0; t < temp.length; t++) {
                        var g = temp[t].group
                        if (groupQuestion.group == g) {
                            groupQuestion.title = temp[t].group_title
                        }
                    }
                    this.questionnaire.groupQuestions.push(groupQuestion)
                    this.questionnaire.groupQuestions[j].messages = []
                    for (let k = 0; k < temp.length; k++) {
                        if (temp[k].group == this.questionnaire.groupQuestions[j].group) {
                            var message = {}
                            message.id = temp[k].question_id
                            message.type = temp[k].question_type
                            message.content = temp[k].content
                            if (temp[k].tableheader.length != 0) {
                                message.headers = JSON.parse(temp[k].tableheader)
                            }
                            message.supplement = temp[k].supplement
                            if (temp[k].options.length != 0) {
                                message.options = JSON.parse(temp[k].options)
                            }
                            if (message.type == "单选题" || message.type == "多选题") {
                                message.ifNewline = false
                                for (let i = 0; i < message.options.length; i++ ) {
                                    if (message.options[i].length > 24) {
                                        message.ifNewline = true
                                    }
                                }
                            }
                            this.questionnaire.groupQuestions[j].messages.push(message)
                        }
                    }
                }
            }
            this.loading = false
        } catch (e) {
            this.loading = false
            this.$message({
                message: '評価スケール取得に失敗しました。更新して再試行してください。',
                type: 'error'
            })
        }
        this.tableData = this.questionnaire.groupQuestions[0].messages
        localStorage.setItem('tableData', JSON.stringify(this.tableData))
        localStorage.setItem('questionnaire', JSON.stringify(this.questionnaire))
        for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
            this.userAnswer.push([])
            for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
                this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer: [], tableData: [], time:"", date:"", supplement: ""})
            }
        }
    },
}
</script>

<style scoped>
 @media screen and (max-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 10px;
    }
}

@media screen and (min-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 30px;
    }
}
.demo-form-inline >>> .el-form-item__label {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.formLeft >>> .el-form-item__label {
    font-size: 20px;
}
.formLeft >>> .el-form-item {
    margin-right: 30px;
}
.radio >>> .el-radio__label {
    font-size: 18px;
}
</style>