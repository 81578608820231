<template>
<div style="height:100%; width: 100%" :height=height :qIndex=qIndex >
    <div v-if="qIndex == 0" id="tui-image-editor-container0" :width=width :logoPath=logoPath></div>
    <div v-if="qIndex == 1" id="tui-image-editor-container1" :width=width :logoPath=logoPath></div>
    <div v-if="qIndex == 2" id="tui-image-editor-container2" :width=width :logoPath=logoPath></div> 
    <el-row style="margin-top: 0.2rem;">
        <el-col :span="12" style="text-align:center">
            <el-button @click="save" type="primary">完了</el-button>
        </el-col>
        <el-col :span="12" style="text-align:center">
            <el-button @click="cancel" type="primary">キャンセル</el-button>
        </el-col>
    </el-row>
</div>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
const customTheme = {
    "common.bi.image": "",
    "common.bisize.width": "0px",
    "common.bisize.height": "0px",
    "common.backgroundImage": "none",
    "header.backgroundImage": "none",
    'loadButton.display': 'none',
    "downloadButton.display": "none",
}
var ImageEditor = require('tui-image-editor');
const localeCN = {
    Draw: "落書き",//涂鸦
    Undo: "一歩戻る",//撤销
    Reset: "リセット",//重置
    Free: "任意の線",  //任意线条
    Straight: "直線",  //直线
    Color: "色",  //颜色
}
    export default {
        props: {
            qIndex: {
                type: Number
            },
            logoPath: {
                type: String,
                default: 'white.png'
            }, 
            width: {
                type: Number,
            }, 
            height: {
                type: Number,
            }, 
        },
        data() {
            return {
                instance: null,
            }
        },
        methods: {
            save() {
                this.$emit('save', this.instance.toDataURL())
                this.cancel()
            },
            cancel() {
                this.$emit('cancel', this.instance.clearObjects())
            }
        },
        mounted() {
            this.instance = new ImageEditor(document.querySelector('#tui-image-editor-container'+this.qIndex), {
                includeUI: {
                    loadImage: {
                        path: require("@/assets/"+ this.logoPath),
                        name: 'SampleImage'
                    },
                    theme: customTheme,
                    menu: ["draw",],
                    initMenu: 'draw',
                    locale: localeCN,
                    menuBarPosition: 'bottom'
                },
                cssMaxWidth: this.width,
                cssMaxHeight: this.height -100,
            });
        }
    }
</script>

<style scoped>
.tui-image-editor-container >>> div.tui-colorpicker-clearfix
{ display: none; }
.tui-image-editor-container >>> .tie-btn-zoomIn 
{ display: none; }
.tui-image-editor-container >>> .tie-btn-zoomOut
{ display: none; }
.tui-image-editor-container >>> .tie-btn-hand
{ display: none; }
.tui-image-editor-container >>> .tie-btn-history
{ display: none; }
.tui-image-editor-container >>> .tie-btn-redo 
{ display: none; }
.tui-image-editor-container >>> .tie-btn-delete 
{ display: none; }
.tui-image-editor-container >>> .tie-btn-deleteAll 
{ display: none; }
.tui-image-editor-container >>> .tui-image-editor-icpartition
{ display: none; }
.tui-image-editor-container >>> .tui-image-editor-help-menu.top{
    width: 150px;
    bottom: 0px;
}
.tui-image-editor-container >>> .tui-image-editor-range-wrap{
    display: none !important;
}
.tui-image-editor-container >>> .tui-image-editor-submenu{
    height: 80px;
}
.tui-image-editor-container.bottom .tui-image-editor-submenu > div{
    padding-bottom: 0px !important;
}
.tui-image-editor-container >>> div.tui-image-editor-menu-draw{
    padding-bottom: 0px;
}
.tui-image-editor-canvas-container{
    margin-left: auto;
    margin-right: auto;
}
.tui-image-editor{
    text-align: center;
}  
.tui-image-editor-container .tui-image-editor-wrap {
    overflow: visible;
}
.tui-image-editor>>> .tui-image-editor-range-wrap{
    display: none;
}
.tui-image-editor >>> .tui-image-editor-header-buttons{
    color: #000;
}
.tui-image-editor >>> .tui-image-editor-size-wrap{
    margin: auto;
    text-align: center;
}
</style>
