<template>
    <div id="LogIn" style="background-color: #ffffff">
        <el-container style="height: 32rem;" >
            <el-header style="height: 5rem"></el-header>
            <el-container>
                <el-main>
                    <el-row>
                        <el-col :sm="{span:16, offset:4}" :md="{span:12, offset:10}">
                            <el-card shadow="hover" class="loginbox" style="height: 25rem; margin: auto; align-items: center;" :body-style="{padding: '2rem'}">
                                <el-form>
                                    <div style="height: 0.5rem"></div>
                                    <div class="title" style="height: 3rem; font-weight: bold;">{{"アンケート調査"}}</div>
                                    <el-form-item>
                                        <el-input v-model="userName"
                                            placeholder="ユーザー名を入力してください"
                                            clearable
                                            >
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input v-model="passWord"
                                            show-password
                                            placeholder="パスワードを入力してください"
                                            @keyup.native.enter="AccountLogin">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item v-loading="loading" style="height: 2rem">
                                        <el-button  type="primary" @click="AccountLogin" v-show="!loading">ログイン</el-button>
                                    </el-form-item>
                                    <div style="height: 0.5rem;"></div>
                                    <div class="notes">{{"お客様が我々のサービス等をご利用になる際、お客様の個人データ及びプライバシーは、安全に守られています。パスワードを大切に保管してください。パスワードを忘れたら、管理者に連絡してください。"}}</div>
                                </el-form>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userName: '',
                passWord: '',
                loading: false,
            }
        },
        methods: {
            AccountLogin() {
                this.accountLogin(this.userName, this.passWord, "account/login", true)
            },
        },
        name: "LogIn",
    }
</script>

<style scoped>
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }
@media screen and (max-width: 768px) {
   .loginbox{
        width: 18rem;
    }
}
@media screen and (min-width: 768px) {
    .loginbox{
        width: 22rem;
    }
}
</style>
