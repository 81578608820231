<template>
    <div style="margin-left: auto; margin-right: auto; ">
        <el-row>
            <el-col :xs="{span:24}" :sm="{span:22, offset:1}" :md="{span:18, offset:3}">
                <el-button type="primary" icon="el-icon-arrow-left" @click="toHome" style="text-decoration:none; float:left; margin-top: 1rem;">戻る</el-button>
                <div class="header" style="margin-top: 2rem"><span style="display: inline;">{{questionnaire.title}}</span></div>
                <div style="margin-top: 0.3rem;"><span style="display: inline;">{{questionnaire.mentor}}</span></div>
                <div v-if="width > 635">
                    <el-form :inline="true" class="demo-form-inline" style="margin-top: 3rem; float: left;" >
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <el-form :label-position="labelPosition" style="margin-top: 3rem; float: left; margin-left: 1rem;" class="formLeft">
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                    <div v-if="group == 0 || group == 3">
                        <el-table
                            class="fabTable"
                            :span-method="objectSpanMethod"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :cell-style="{'height':'100%'}"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="120" align="center">
                                <span class="groupTitle">
                                    {{group+1}}.{{groupQuestion.title}}
                                </span>
                            </el-table-column>
                            <el-table-column min-width="250">
                                <template slot-scope="scope">
                                    {{groupQuestion.messages[scope.$index].content}}
                                    <div v-if="group == 0">
                                        <div v-if="scope.$index == 0">
                                            <el-image v-if="srcLists[group][scope.$index]" style="width: 100px" :src="srcLists[group][scope.$index]" fit="contain" :preview-src-list="[srcLists[group][scope.$index]]"></el-image>
                                            <div v-else>
                                                <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index"  style="width: 100px " :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                            </div>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-button type="text" @click="openDialog(0)">描き始める</el-button>
                                                </el-col>
                                            </el-row>
                                            <el-dialog :visible.sync="dialogTableVisible" width="100%" :fullscreen=true class="DrawDialog">
                                                <DrawImage v-if="refresh" :width="width*0.85" :height="height" :qIndex="0" :logoPath="groupQuestion.messages[0].logoPath" v-on:save="saveSrc($event, group)" v-on:cancel="closeDialog(0)"></DrawImage>
                                            </el-dialog>
                                        </div>
                                        <div v-else-if="scope.$index == 2">
                                            <el-image v-if="srcLists[group][scope.$index]" style="width: 100px" :src="srcLists[group][scope.$index]" fit="contain" :preview-src-list="[srcLists[group][scope.$index]]"></el-image>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-button type="text" @click="openDialog(2)">描き始める</el-button>
                                                </el-col>
                                            </el-row>
                                            <el-dialog :visible.sync="dialogTableVisible2" width="100%" :fullscreen=true class="DrawDialog">
                                                <DrawImage v-if="refresh" :width="width*0.85" :height="height" :qIndex="2" :logoPath="groupQuestion.messages[2].logoPath" v-on:save="saveSrc($event, group)" v-on:cancel="closeDialog(2)"></DrawImage>
                                            </el-dialog>
                                        </div>
                                        <div v-else>
                                            <el-row type="flex" v-if="srcLists[group][scope.$index]" style="height: 100%">
                                                <el-col :xs="24" :sm="24" :md="10">
                                                    <div style="display: flex; height: 100%">
                                                        <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index"  style="height: 60px; align-self: flex-start" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="24" :md="14">
                                                    <div style="display: flex; height: 100%">
                                                        <el-image v-if="srcLists[group][scope.$index]" style="height: 60px; align-self: flex-start" :src="srcLists[group][scope.$index]" fit="contain" :preview-src-list="[srcLists[group][scope.$index]]"></el-image>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row v-else style="height: 100%">
                                                <el-col :span="24">
                                                    <div>
                                                        <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index"  style="height: 60px; " :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-button type="text" @click="openDialog(1)">描き始める</el-button>
                                                </el-col>
                                            </el-row>
                                            <el-dialog :visible.sync="dialogTableVisible1" width="100%" :fullscreen=true class="DrawDialog">
                                                <el-row  type="flex" style="height: 100%; width: 100%">
                                                    <el-col :span="6">
                                                        <div style="display: flex; height: 100%; margin: auto; width: 100%">
                                                            <div class="block" style="align-self: center; margin: auto;">
                                                                <el-image v-for="(image, index) in groupQuestion.messages[qIndex].imageArray" :key="index" :width="width*0.25" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                                                <div style="margin-top: 0px; text-align: center;">
                                                                    <span style="text-align: center;">原図</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <DrawImage v-if="refresh" :width="width*0.75" :height="height" :qIndex="1" :logoPath="groupQuestion.messages[1].logoPath" v-on:save="saveSrc($event, group)" v-on:cancel="closeDialog(1)"></DrawImage>
                                                    </el-col>
                                                </el-row>
                                            </el-dialog>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="得点">
                                <template slot-scope="scope">
                                    <div v-if="group == 0">
                                        <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                            <el-radio class="radio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options.option" :key="optionIndex" :label="optionIndex">{{option.label}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div v-else>
                                        <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                            <el-radio class="radio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-else-if="group == 4">
                        <el-table
                            class="fabTable"
                            :span-method="objectSpanMethod1"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="120" align="center">
                                <span class="groupTitle">
                                    {{group+1}}.{{groupQuestion.title}}
                                </span>
                            </el-table-column>
                            <el-table-column min-width="250">
                                <template slot-scope="scope">
                                    {{groupQuestion.messages[scope.$index].content}}
                                    <div v-if="scope.$index === 1">
                                    <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="得点">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                        <el-radio class="radio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 表格有三列数据 -->
                    <div v-else-if="group == 1 || group == 5 || group == 7 || group == 2 || group == 6">
                        <el-table
                            class="fabTable"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="120" align="center">
                                <span class="groupTitle">
                                    {{group+1}}.{{groupQuestion.title}}
                                </span>
                            </el-table-column>
                            <el-table-column min-width="250">
                                <template slot-scope="scope">
                                    {{groupQuestion.messages[scope.$index].content}}
                                    <div v-if="group === 1">
                                        <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index"  style="margin-left: auto; margin-right: auto; height: 90px; " :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                    </div>
                                    <div v-if="group === 2">
                                        <el-row style="white-space: normal;">
                                            <el-col :span="6">
                                                {{groupQuestion.messages[scope.$index].options.optionTitle[0]}}
                                            </el-col>
                                            <el-col :span="18">
                                                <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer[0]">
                                                    <el-checkbox class="checkBox" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :label="item.label" :key="optionIndex">{{item.label}}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-col>
                                        </el-row>
                                        <el-row style="white-space: normal;">
                                            <el-col :span="6">
                                                {{groupQuestion.messages[scope.$index].options.optionTitle[1]}}
                                            </el-col>
                                            <el-col :span="18">
                                                <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer[1]">
                                                    <el-checkbox class="checkBox" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :label="item.label" :key="optionIndex">{{item.label}}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div v-if="group === 6">
                                        <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer">
                                            <el-checkbox class="checkBox" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :label="optionIndex" :key="optionIndex">{{item.label}}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div v-if="group === 7">
                                        <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="得点">
                                <template slot-scope="scope">
                                    <div v-if="group == 6">
                                        <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                            <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="optionIndex">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div v-if="group == 1 || group == 5 || group == 7">
                                        <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                            <el-radio class="radio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="group == 8">
                        <el-table
                            class="fabTable"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="120" align="center"></el-table-column>
                            <el-table-column min-width="250">
                                <template slot-scope="scope">
                                    {{groupQuestion.messages[scope.$index].content}}
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                        <el-radio class="radio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex">{{option}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div v-loading="loading" style="margin-top: 3rem; margin-bottom: 3rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document">確 定</el-button>
                </div>
            </el-col>
        </el-row>
        <div id="model" class="model" :style="modelStyle">
            <div style="width:100%;height:100%; display:table">
                <!-- 请调整设备为竖屏后进行画图 -->
                <div class="model-content">{{"描画する前に、デバイスを垂直画面に調整してください"}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DrawImage from '../DrawImage.vue';
export default {
    components: {
        DrawImage
    },
    data() {
        return {
            modelStyle: {width: document.documentElement.clientWidth + 'px', height: document.documentElement.clientHeight + 'px'},
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
            srcLists: [],
            tableData: [],
            userAnswer: [],
            questionnaire: {},
            loading: false,
            name: '',
            username: '',
            patientName: '',
            patientID: '',
            dialogTableVisible: false,
            dialogTableVisible2: false,
            dialogTableVisible1: false,
            qIndex: 0,
            refresh: true,
            labelPosition: "left",
            ifPhone: '',
        }
    },
    watch: {
        qIndex() {
            this.refresh = false
            this.$nextTick(() => {
                this.refresh = true
            })
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.width = document.documentElement.clientWidth
            this.height = document.documentElement.clientHeight
            this.modelStyle.width = document.documentElement.clientWidth + 'px'
            this.modelStyle.height = document.documentElement.clientHeight + 'px'
            if (this.ifPhone == true){
                if (this.width < this.height) {//尺寸变换时,判断横竖屏,当是竖屏时,隐藏蒙版
                    document.getElementById("model").style.display = 'none';
                } else if(this.width > this.height && (this.dialogTableVisible == true||this.dialogTableVisible1 == true||this.dialogTableVisible2 == true)) {
                    document.getElementById("model").style.display = 'block';
                }
            }
        },
        toHome() {
            this.$router.push({path: '/questionnaire'})
        },
        openDialog(qIndex) {//打开对话框时,判断横竖屏,当是横屏时,需要显示该蒙版
            this.qIndex = qIndex
            if (qIndex == 0) {
                this.dialogTableVisible = true
            } else if (qIndex == 1) {
                this.dialogTableVisible1 = true
            }else if (qIndex == 2) {
                this.dialogTableVisible2 = true
            }
            if (this.ifPhone == true && this.height < 600){
                if (this.width > this.height){
                    document.getElementById("model").style.display="block";
                }
            }
        },
        saveSrc(src, group) {
            this.srcLists[group][this.qIndex] = src
        },
        closeDialog(qIndex) {
            if (qIndex == 0) {
                this.dialogTableVisible = false
            } else if (qIndex == 1) {
                this.dialogTableVisible1 = false
            }else if (qIndex == 2) {
                this.dialogTableVisible2 = false
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex}) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 3,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        objectSpanMethod1({ row, column, rowIndex, columnIndex}) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        async submitData() {
            this.loading = true
            console.log("gethere")
            try {
                let res = await axios({
                    method: 'post',
                    url: window.serverURL + 'answer',
                    data: {
                        Name: this.name,
                        UserName: this.username,
                        SurveyName: this.questionnaire.title,
                        SurveyType: this.questionnaire.type,
                        SurveyData: this.userAnswer,
                        PatientName: this.patientName,
                        PatientID: this.patientID,
                    },
                })
                this.$message({
                    message: 'データの送信が成功しました！',
                    type: 'success'
                })
                var value = Object.values(res.data.data)
                var key = Object.keys(res.data.data)
                for (let i = 0; i < key.length; i++) {
                    this.$set(this.$store.state.pdfdata, key[i], value[i])
                }
                localStorage.setItem('srcLists', JSON.stringify(this.srcLists))
                localStorage.setItem('userAnswer', JSON.stringify(this.userAnswer))
                localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                this.$router.push({path:'/report/' + this.questionnaire.type})
            } catch (e) {
                this.loading = false
                this.$message({
                    message: 'データの送信に失敗しました。もう一度送信してください。',
                    type: 'error'
                })
            }
        },
    },
    async created() {
        if (/(iPhone|Android)/i.test(navigator.userAgent)) { //判断iPhone|Android
            this.ifPhone = true
        } else { //pc|ipad
            this.ifPhone = false
        }
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        try {
            let res = await axios.get(window.serverURL + "question?name=MoCA")
            var temp = res.data.data
                for (let i = 0; i < temp.length; i++) {
                    this.questionnaire = {}
                    this.$set(this.questionnaire,"id",temp[0].id)
                    this.$set(this.questionnaire,"type",temp[0].type)
                    this.$set(this.questionnaire,"title",temp[0].title)
                    this.$set(this.questionnaire,"mentor",temp[0].mentor)
                    this.$set(this.questionnaire,"mentorJudge",temp[0].mentorJudge)
                    this.questionnaire.groupQuestions = []
                    var l = temp.length
                    var grouplength = temp[l-1].group + 1
                    for (let j = 0; j < grouplength; j++ ) {
                        var groupQuestion = {}
                        groupQuestion.group = j
                        for (let t = 0; t < temp.length; t++) {
                            var g = temp[t].group
                            if (groupQuestion.group == g) {
                                groupQuestion.title = temp[t].group_title
                            }
                        }
                        this.questionnaire.groupQuestions.push(groupQuestion)
                        this.questionnaire.groupQuestions[j].messages = []
                        for (let k = 0; k < temp.length; k++) {
                            if (temp[k].group == this.questionnaire.groupQuestions[j].group) {
                                var message = {}
                                message.id = temp[k].question_id
                                message.type = temp[k].question_type
                                message.content = temp[k].content
                                if (temp[k].tableheader.length != 0) {
                                    message.headers = JSON.parse(temp[k].tableheader)
                                }
                                message.supplement = temp[k].supplement
                                if (temp[k].options.length != 0) {
                                    message.options = JSON.parse(temp[k].options)
                                }
                                
                                if (message.type == "双选择题") {
                                    var optionDisabled = [true, true]
                                    if (message.options.option1.length != 0) {
                                        optionDisabled[0] = false
                                    }
                                    if (message.options.option2.length != 0) {
                                        optionDisabled[1] = false
                                    } 
                                    message.options.optionDisabled = optionDisabled
                                }
                                if (message.type == "单选题" || message.type == "多选题") {
                                    message.ifNewline = false
                                    for (let i = 0; i < message.options.length; i++ ) {
                                        if (message.options[i].length > 24) {
                                            message.ifNewline = true
                                        }
                                    }
                                }
                                message.imageArray = []//每道题目的图片都为一个数组的形式
                                var files = ''
                                files = require.context('@/assets/MoCA', false, /\.png$/)
                                var questionId = [];
                                var questionIndexInFiles = []
                                var group = (parseInt(this.questionnaire.groupQuestions[j].group+1)).toString()
                                if (this.questionnaire.groupQuestions[j].group < 10) {
                                    group = "0" + (parseInt(this.questionnaire.groupQuestions[j].group+1)).toString()
                                }
                                for (let i = 0; i < files.keys().length; i++) {
                                    if (files.keys()[i].substr(2, 2) == group){
                                        questionIndexInFiles.push(i)
                                        questionId.push(
                                            files.keys()[i].substr(5, 2)
                                        )
                                    }
                                }
                                var id = (parseInt(message.id)).toString()
                                if (message.id < 10) {
                                    id = "0" + (parseInt(message.id)).toString()
                                }
                                var images = [];//截取图片的名称
                                for (let i = 0; i < questionId.length; i++) {
                                    if (questionId[i] == id) {
                                        images.push(
                                            files.keys()[questionIndexInFiles[i]].substr(2)
                                        )
                                    }
                                }
                                for (let i = 0; i < images.length; i++) {
                                    message.imageArray.push(require('@/assets/MoCA/' + images[i]))
                                }
                                if (message.type == "画图题") {
                                    if (message.options.ifWhite == false) {
                                        message.logoPath = "MoCA/"+ images[0]
                                    } else {
                                        message.logoPath = 'white.png'
                                    }
                                }
                                this.questionnaire.groupQuestions[j].messages.push(message)
                            }
                        }
                    }
                }
            this.loading = false
        } catch (e) {
            this.loading = false
            this.$message({
                message: '評価スケール取得に失敗しました。更新して再試行してください。',
                type: 'error'
            })
        }
        for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
            this.srcLists.push([])
            this.tableData.push(this.questionnaire.groupQuestions[i].messages)
            this.userAnswer.push([])
                for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
                    this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer:[], tableData: [], time:"", date:"", supplement: ""})
                    switch (this.questionnaire.groupQuestions[i].messages[j].type) {
                        case "双选择题":
                            this.userAnswer[i][j].listAnswer.push([])
                            this.userAnswer[i][j].listAnswer.push([])
                            break
                        default:
                            break
                    }
                }
        }
        localStorage.setItem('questionnaire', JSON.stringify(this.questionnaire))
        localStorage.setItem('tableData', JSON.stringify(this.tableData))
    },
}
</script>

<style scoped>
.model{
    margin: auto;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: rgba(10, 10, 10, 0.705);
    display: none;
    z-index: 3000;
}
.model-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #ffffff;
    z-index: 10001;
    font-size: 40px;
}
.el-form--inline >>> .el-form-item {
    margin-right: 30px;
}
.demo-form-inline >>> .el-form-item__label {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.formLeft >>> .el-form-item__label {
    font-size: 20px;
}
.formLeft >>> .el-form-item {
    margin-right: 30px;
}
.fabTable >>> .cell {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 20px;
    padding-right: 1px;
    left: 0px;
    top: 0px;

}
.radio >>> .el-radio__label {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
}
.groupTitle{
    word-break: normal;
}
.DrawDialog>>> .el-dialog__body{
    height: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
}
.DrawDialog >>> .el-dialog__header{
    display: none;
}
</style>