<template>
    <div>
        <el-container>
            <el-header class="header" height="4rem" style="background-color: #4682B4; position: fixed; width: 100%; z-index: 3">
                <el-row>
                    <el-col :span="8" :offset="8">
                        <div style="height:0.6rem;"></div>
                        <div><span class="header" style="display: inline; color: #fff; font-weight: 600;">評価スケール</span></div>
                    </el-col>
                    <el-col :span="8">
                        <el-dropdown style="color: #fff; font-size: 15px; margin-top: 2rem">
                            <span class="el-dropdown-link">{{"hello，"}}{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item divided @click.native="toPersonalCenter">センター</el-dropdown-item>
                                <el-dropdown-item divided @click.native="logout">ログアウト</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </el-header>
            <el-main style="margin-top: 4rem; padding: 0px; width:100%;" :style="mainStyle">
                <el-row style="margin-top: 5%">
                    <el-col :xs="24" :sm="{span:20, offset:2}" :md="{span:16, offset:4}">
                        <div class="tabDiv">
                            <el-tabs class="qTabs" v-model="activeName" @tab-click="handleClick" style="width: 100%">
                                <el-tab-pane label="グループ化A" name="first">
                                    <el-table
                                        class="ScaleLists"
                                        :data="questionnaireLists"
                                        style="width: 100%;"
                                        :show-header="false"
                                        :cell-style="{'height':'100px','line-height':'30px'}">
                                        <el-table-column width="60">
                                            <template slot-scope="scope" style="text-align:center">
                                                <el-avatar shape="square" size="medium" class="avatar">{{scope.row.title.charAt(0)}}</el-avatar>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="title"
                                            min-width="100">
                                        </el-table-column>
                                        <el-table-column
                                            prop="type"
                                            min-width="50">
                                        </el-table-column>
                                        <el-table-column min-width="50">
                                            <template slot-scope="scope">
                                                <el-link
                                                    @click="toTest(scope.row.id)"
                                                    type="primary"
                                                    class="ScaleLists">
                                                        {{"テストを開始します "}}
                                                </el-link>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="グループ化B" name="second">
                                    <el-table
                                        class="ScaleLists"
                                        :data="questionnaireLists"
                                        style="width: 100%;"
                                        :show-header="false"
                                        :cell-style="{'height':'100px','line-height':'30px'}">
                                        <el-table-column
                                            width="60">
                                            <template slot-scope="scope">
                                                <el-avatar shape="square" size="medium" class="avatar">{{scope.row.title.charAt(0)}}</el-avatar>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="title"
                                            min-width="100">
                                        </el-table-column>
                                        <el-table-column
                                            prop="type"
                                            min-width="50">
                                        </el-table-column>
                                        <el-table-column min-width="50">
                                            <template slot-scope="scope">
                                                <el-link
                                                    @click="toTest(scope.row.id)"
                                                    type="primary"
                                                    class="ScaleLists">
                                                        {{"テストを開始します "}}
                                                </el-link>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        </el-col>
                    </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            return {
                activeName: 'first',
                mainStyle: {height: window.innerHeight / 16 -4 + 'rem'},
                questionnaireLists: [],
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                input: '',
                username:'',
                questionnaires: [],
                list:[],
                listA: [],
                listB: [],
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.mainStyle.height = window.innerHeight / 16 - 4 + 'rem';
            },
            handleClick(tab) {
                if (tab.label == "グループ化A") {
                    this.questionnaireLists = []
                    this.questionnaireLists = this.listA
                }
                if (tab.label == "グループ化B") {
                    this.questionnaireLists = []
                    this.questionnaireLists = this.listB
                }
            },
            toTest(index) {
                if(index === 2) {
                    this.$router.push({path: '/questionnaire/apathy%20score'})
                } else {
                    for (let i = 0; i < this.list.length; i++) {
                        if (this.list[i].id === index) {
                            this.$router.push({path: '/questionnaire/'+ this.list[i].type})
                        }
                    }
                }
                // for (let i = 0; i < this.questionnaires.length; i++) {//先注释掉提交问题，提交问卷数据之后再注释掉提交问卷，提交问题
                //     if (this.questionnaires[i].type === this.questionnaireLists[index].type) {
                //         let questionnaire = this.questionnaires[i]
                //         // axios.post(window.serverURL + 'questionnaire', {ID: questionnaire.id, QuestionnaireType: questionnaire.type, QuestionnaireTitle:questionnaire.title, Mentor: questionnaire.mentor, MentorJudge: questionnaire.mentorJudge})
                //         //     .then(response => {
                //         //         this.$message({
                //         //             message: '问卷数据提交成功！',
                //         //             type: 'success'
                //         //         })
                //         //         this.loading = false
                //         //     })
                //         //     .catch(error => {
                //         //         this.loading = false
                //         //         this.$message({
                //         //             message: '问卷数据提交失败，请再次尝试提交。',
                //         //             type: 'error'
                //         //         })
                //         //     })
                //         for (let j = 0; j < questionnaire.groupQuestions.length; j++) {//提交问题
                //             for(let k = 0; k < questionnaire.groupQuestions[j].messages.length; k++){
                //                 let optionString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].options)
                //                 let headersString = JSON.stringify(questionnaire.groupQuestions[j].messages[k].headers)
                //                 axios.post(window.serverURL + 'question', {
                //                     QuestionnaireID: questionnaire.id,
                //                     Group: questionnaire.groupQuestions[j].group,
                //                     GroupTitle: questionnaire.groupQuestions[j].title,
                //                     QuestionID: questionnaire.groupQuestions[j].messages[k].id,
                //                     QuestionType: questionnaire.groupQuestions[j].messages[k].type,
                //                     Content: questionnaire.groupQuestions[j].messages[k].content,
                //                     Supplement: questionnaire.groupQuestions[j].messages[k].supplement,
                //                     TableHeader: headersString,
                //                     Options: optionString
                //                 })
                //                     .then(response => {
                //                         this.$message({
                //                             message: '问题数据提交成功！',
                //                             type: 'success'
                //                         })
                //                         this.loading = false
                //                     })
                //                     .catch(error => {
                //                         this.loading = false
                //                         this.$message({
                //                             message: '问题数据提交失败，请再次尝试提交。',
                //                             type: 'error'
                //                         })
                //                     })
                //             }
                //         }
                //     }
                // }
            },
            toPersonalCenter() {
                this.$router.push({path:'/personalCenter'})
            },
            logout() {
                this.$store.commit('logOut')
                this.$router.push('/login')
            },
            async searchAllScale() {
                try {
                    let res = await axios.get(window.serverURL + "questionnaire")
                    this.list.push(res.data.data[0])
                    this.list.push(res.data.data[1])
                    this.list.push(res.data.data[2])
                    this.list.push(res.data.data[3])
                    this.list.push(res.data.data[4])
                    this.list.push(res.data.data[9])
                    this.list.push(res.data.data[6])
                    this.list.push(res.data.data[8])
                    for (let i = 0; i < 4; i++) {
                        this.listA.push(this.list[i])
                    }
                    for (let i = 4; i < 8; i++) {
                        this.listB.push(this.list[i])
                    }
                    this.questionnaireLists = this.listA
                    this.loading = false
                } catch (e) {
                    this.$message({
                        message: '評価スケール取得に失敗しました。更新して再試行してください。',
                        type: 'error'
                    });
                }
            }
        },
        created() {
            if (this.$route.path !== '/login') {
                this.loading = true
                axios.get(window.serverURL + 'verify')
                    .then(response => {
                        if (response.status === 200) {
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$store.commit('needLogin')
                    })
                    .catch(error => {
                        this.loading = false
                        this.$router.push('/login')
                    })
            }
            this.searchAllScale()
            this.username = localStorage.getItem('username')
        },
    }
</script>

<style scoped>
.block {
    padding: 10px 0;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
    .box-card {
        cursor: pointer;
        transition: all 0.6s;
        overflow: visible;
    }
    .box-card:hover {
        transform: scale(1.05);
    }
    .el-main /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    #app {
        background-color: #efefef;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        color: #2c3e50;
    }
    body {
        margin: 0;
        padding: 0;
    }
    .header{
        display: inline-block;
        font-size: 30px; 
    }
    .ScaleLists >>> .cell {
        line-height: 30px;
    }
    @media screen and (max-width: 769px) {
        .el-tabs>>> .el-tabs__item {
            font-size: 16px;
        }
        .avatar{
            font-size: 16px;
        }
        .ScaleLists{
            font-size: 16px;
            word-wrap : break-word ;
        }
        .header{
            font-size: 23px; 
        }
}
@media screen and (min-width: 769px) {
    .el-tabs>>> .el-tabs__item {
        font-size: 20px;
    }
    .avatar{
        font-size: 20px;
    }
    .ScaleLists{
        font-size: 25px;
        word-wrap : break-word ;
    }
    .header{
        font-size: 30px;
    }
}
</style>
