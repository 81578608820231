<template>
  <div id="app" style="height: 100%">
    <div id="nav">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,      
      }
    },
  }
</script>

<style>
#app {
  background-color: #ffffff;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #000000;
}
body {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  margin: 0;
  padding: 0;
}
</style>
