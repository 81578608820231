<template>
    <div class="mmseDiv">
        <el-row>
            <el-col :xs="{span:24}" :sm="{span:22, offset:1}" :md="{span:18, offset:3}">
                <el-button type="primary" icon="el-icon-arrow-left" @click="toHome" style="text-decoration:none; float:left; margin-top: 1rem;">戻る</el-button>
                <div class="header" style="margin-top: 2rem"><span style="display: inline;">{{questionnaire.title}}</span></div>
                <div style="margin-top: 0.3rem;"><span style="display: inline;">{{questionnaire.mentor}}</span></div>
                <div v-if="width > 635">
                    <el-form :inline="true" class="demo-form-inline" style="margin-top: 3rem; float: left;" >
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <el-form :label-position="labelPosition" style="margin-top: 3rem; float: left; margin-left: 1rem;" class="formLeft">
                        <el-form-item label="氏名:">
                            <el-input v-model="patientName" placeholder="氏名"></el-input>
                        </el-form-item>
                        <el-form-item label="診察ID番号:">
                            <el-input v-model="patientID" placeholder="診察ID番号"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 表头固定，故在一个表格中显示数据-->
                <el-table
                    :data="res"
                    border
                    :span-method="objectSpanMethod"
                    :max-height=height-150
                    :header-cell-style="{'text-align':'center'}"
                    style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                    <el-table-column width="60" align="center">
                        <template slot-scope="scope">
                            {{res[scope.$index].group+1}}
                        </template>
                    </el-table-column>
                    <el-table-column label="質問内容">
                        <template slot-scope="scope">
                            {{res[scope.$index].content}}
                            <div v-if="scope.$index == 22">
                                <el-row type="flex" v-if="srcLists[0][22]">
                                    <el-col :xs="24" :sm="24" :md="10">
                                        <div style="display: flex; height: 100%">
                                            <el-image style=" height: 100px; align-self: flex-start" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="14">
                                        <div style="display: flex; height: 100%">
                                            <el-image v-if="srcLists[0][22]" style="height: 100px; align-self: flex-start" :src="srcLists[0][22]" fit="contain" :preview-src-list="[srcLists[0][22]]"></el-image>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row v-else>
                                    <el-col :span="24">
                                        <div>
                                            <el-image style=" height: 100px; float: left;" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-button type="text" @click="openDialog">描き始める</el-button>
                                <el-dialog :visible.sync="dialogTableVisible" width="100%" :fullscreen=true class="DrawDialog">
                                    <el-row type="flex" style="height: 100%">
                                        <el-col :span="6">
                                            <div style="display: flex; height: 100%; margin: auto; width: 100%">
                                                <div class="block" style="align-self: center; margin: auto">
                                                    <el-image :src="image" fit="contain" :preview-src-list="[image]" ></el-image>
                                                    <div style="text-align: center">
                                                        <span class="demonstration" style="text-align: center">原図</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <DrawImage :width="width*0.75" :height="height" :qIndex="0" v-on:save="saveSrc($event, 0, 22)" v-on:cancel="closeDialog()"></DrawImage>
                                        </el-col>
                                    </el-row>
                                </el-dialog>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="20">
                                    <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[0][scope.$index].supplement" clearable></el-input>
                                </el-col>
                                <el-col :span="4">
                                    <span v-if="scope.$index == 0">{{"歳"}}</span>
                                    <span v-if="scope.$index == 2">{{"年"}}</span>
                                    <span v-if="scope.$index == 4">{{"月"}}</span>
                                    <span v-if="scope.$index == 5">{{"日"}}</span>
                                    <span v-if="scope.$index == 6">{{"曜日"}}</span>
                                    <span v-if="scope.$index == 7">{{"県"}}</span>
                                    <span v-if="scope.$index == 8">{{"市"}}</span>
                                    <span v-if="scope.$index == 9">{{"病院"}}</span>
                                    <span v-if="scope.$index == 10">{{"階"}}</span>
                                    <span v-if="scope.$index == 11">{{"地方"}}</span>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column  label="MMS">
                        <template slot-scope="scope">
                            <div v-if="scope.$index == 22">
                                <el-radio-group v-model="userAnswer[0][scope.$index].choiceAnswer">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1" :key="optionIndex" :label="optionIndex">{{item.label}}</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-else-if="scope.$index == 16 || scope.$index == 18">
                                <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][0]" style="width:100%">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option11" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                </el-radio-group>
                                <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][1]" style="width:100%">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option12" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                </el-radio-group>
                                <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][2]" style="width:100%">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option13" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                </el-radio-group>
                            </div>
                            <el-radio-group v-else v-model="userAnswer[0][scope.$index].listAnswer[0]" style="width:100%">
                                <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                            </el-radio-group>
                            <el-input v-if="scope.$index == 12" type="textarea" autosize placeholder="繰り返した回数" v-model="userAnswer[0][scope.$index].supplement" clearable></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="HDS-R">
                        <template slot-scope="scope">
                            <div v-if="scope.$index == 12 || scope.$index == 14">
                                <el-row style="white-space: normal;">
                                    <el-col :span="4">
                                        {{"a)"}}
                                    </el-col>
                                    <el-col :span="20">
                                        <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][0]">
                                            <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option21" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </el-col>
                                </el-row>
                                <el-row style="white-space: normal;">
                                    <el-col :span="4">
                                        {{"b)"}}
                                    </el-col>
                                    <el-col :span="20">
                                        <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][1]">
                                            <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option22" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </el-col>
                                </el-row>
                                <el-row style="white-space: normal;">
                                    <el-col :span="4">
                                        {{"c)"}}
                                    </el-col>
                                    <el-col :span="20">
                                        <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][2]">
                                            <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option23" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-radio-group v-else v-model="userAnswer[0][scope.$index].listAnswer[1]" style="width:100%">
                                <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                            </el-radio-group>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group"> -->
                    <!-- HDS-R有多个选项框 -->
                    <!-- <div v-if="group === 4 || group === 6">
                        <el-table
                            class="Table"
                            :span-method="objectSpanMethod"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="60" align="center">
                                <template>
                                    {{group+1}}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="220" prop="content" label="質問内容"></el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="MMS" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width: 100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label" :value="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                    <el-input v-if="group == 4" type="textarea" autosize placeholder="繰り返した回数" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="HDS-R" min-width="100">
                                <template slot-scope="scope">
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"a)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][0]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option21" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"b)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][1]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option22" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"c)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][2]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option23" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div> -->
                    <!-- MMS有多个选项框 -->
                    <!-- <div v-else-if="group === 8 || group === 10">
                        <el-table
                            class="Table"
                            :span-method="objectSpanMethod"
                            :data="tableData[group]"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="60" align="center">
                                <template>
                                    {{group+1}}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="220" prop="content" label="質問内容"></el-table-column>
                             <el-table-column>
                                <template slot-scope="scope">
                                    <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="MMS" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][0]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option11" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][1]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option12" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][2]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option13" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column label="HDS-R" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div> -->
                    <!-- 需要有输入框 -->
                    <!-- <div v-else-if="group === 0 || group === 2 || group === 3 || group === 15 || group === 13">
                        <el-table
                            class="Table"
                            :data="tableData[group]"
                            :span-method="objectSpanMethod2"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="60" align="center">
                                <template>
                                    {{group+1}}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="220" prop="content" label="質問内容"></el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-row>
                                        <el-col :span="20">
                                            <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                        </el-col>
                                        <el-col :span="4">
                                            <span v-if="group == 0">{{"歳"}}</span>
                                            <span v-if="group == 2 && scope.$index == 0">{{"年"}}</span>
                                            <span v-if="group == 2 && scope.$index == 2">{{"月"}}</span>
                                            <span v-if="group == 2 && scope.$index == 3">{{"日"}}</span>
                                            <span v-if="group == 2 && scope.$index == 4">{{"曜日"}}</span>
                                            <span v-if="group == 3 && scope.$index == 0">{{"県"}}</span>
                                            <span v-if="group == 3 && scope.$index == 1">{{"市"}}</span>
                                            <span v-if="group == 3 && scope.$index == 2">{{"病院"}}</span>
                                            <span v-if="group == 3 && scope.$index == 3">{{"階"}}</span>
                                            <span v-if="group == 3 && scope.$index == 4">{{"地方"}}</span>
                                        </el-col>
                                    </el-row>
                                </template>
                            </el-table-column>
                            <el-table-column label="MMS" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column label="HDS-R" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-else>
                        <el-table
                            class="Table"
                            :data="tableData[group]"
                            :span-method="objectSpanMethod"
                            border
                            :show-header="group == 0"
                            :header-cell-style="{'text-align':'center'}"
                            style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                            <el-table-column width="60" align="center">
                                <template>
                                    {{group+1}}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="220">
                                <template slot-scope="scope">
                                    {{groupQuestion.messages[scope.$index].content}}
                                    <div v-if="groupQuestion.messages[scope.$index].type === '画图题'">
                                        <el-row type="flex" v-if="srcLists[group][scope.$index]">
                                            <el-col :xs="24" :sm="24" :md="10">
                                                <div style="display: flex; height: 100%">
                                                    <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index" style=" height: 100px; align-self: flex-start" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                                </div>
                                            </el-col>
                                            <el-col :xs="24" :sm="24" :md="14">
                                                <div style="display: flex; height: 100%">
                                                    <el-image v-if="srcLists[group][scope.$index]" style="height: 100px; align-self: flex-start" :src="srcLists[group][scope.$index]" fit="contain" :preview-src-list="[srcLists[group][scope.$index]]"></el-image>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row v-else>
                                            <el-col :span="24">
                                                <div>
                                                    <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index" style=" height: 100px; float: left;" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-button type="text" @click="openDialog">描き始める</el-button>
                                        <el-dialog :visible.sync="dialogTableVisible" width="100%" :fullscreen=true class="DrawDialog">
                                            <el-row type="flex" style="height: 100%">
                                                <el-col :span="6">
                                                    <div style="display: flex; height: 100%; margin: auto; width: 100%">
                                                        <div class="block" style="align-self: center; margin: auto">
                                                            <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index" :src="image" fit="contain" :preview-src-list="[image]" ></el-image>
                                                            <div>
                                                                <span class="demonstration" style="text-align: center">原図</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="18">
                                                    <DrawImage :width="width*0.75" :height="height" :qIndex="0" :logoPath="groupQuestion.messages[scope.$index].logoPath" v-on:save="saveSrc($event, group, scope.$index)" v-on:cancel="closeDialog()"></DrawImage>
                                                </el-col>
                                            </el-row>
                                        </el-dialog>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[group][scope.$index].supplement" clearable></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="MMS" min-width="100">
                                <template slot-scope="scope">
                                    <div v-if="groupQuestion.messages[scope.$index].type === '画图题'">
                                        <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                            <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="optionIndex">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div v-else>
                                        <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]">
                                            <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                        </el-radio-group>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="HDS-R" min-width="100">
                                <template slot-scope="scope">
                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1]">
                                        <el-radio class="radio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label">{{item.label}}</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div> -->
                <div v-loading="loading" style="margin-top: 3rem; margin-bottom: 3rem">
                    <el-button @click="submitData" type="primary" icon="el-icon-document">確 定</el-button>
                </div>
            </el-col>
        </el-row>
        <div id="model" class="model" :style="modelStyle">
            <div style="width:100%; height:100%; display:table">
                <div class="model-content">{{"描画する前に、デバイスを垂直画面に調整してください"}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DrawImage from '../DrawImage.vue';
export default {
    components: {
        DrawImage
    },
    data() {
        return {
            modelStyle: {width: document.documentElement.clientWidth + 'px', height: document.documentElement.clientHeight + 'px'},
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
            srcLists: [],
            tableData: [],
            userAnswer: [],
            questionnaire: {},
            loading: false,
            name: '',
            username: '',
            patientName: '',
            patientID: '',
            dialogTableVisible: false,
            labelPosition: "left",
            ifPhone: '',
            res: [],
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.width = document.documentElement.clientWidth
            this.height = document.documentElement.clientHeight
            this.modelStyle.width = document.documentElement.clientWidth + 'px'
            this.modelStyle.height = document.documentElement.clientHeight + 'px'
            if (this.ifPhone == true){
                if (this.width < this.height) {//尺寸变换时,判断横竖屏,当是竖屏时,隐藏蒙版
                    document.getElementById("model").style.display = 'none';
                } else if(this.width > this.height && this.dialogTableVisible == true) {
                    document.getElementById("model").style.display = 'block';
                }
            }
        },
        toHome() {
             this.$router.push({path: '/questionnaire'})
        },
        saveSrc(src, group, qIndex) {
            this.srcLists[group][qIndex] = src
        },
        openDialog() {//打开对话框时,判断横竖屏,当是横屏时,需要显示该蒙版
            this.dialogTableVisible = true
            if (this.ifPhone == true && this.height < 600){
                if (this.width > this.height){
                    document.getElementById("model").style.display="block";
                }
            }
        },
        closeDialog() {
            this.dialogTableVisible = false
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex}) {
            if (columnIndex === 0) {
                if (rowIndex === 2) {
                    return {
                        rowspan: 5,
                        colspan: 1
                    };
                } else if (rowIndex === 7){
                    return {
                        rowspan: 5,
                        colspan: 1
                    };
                } else if (rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 6 || rowIndex === 8 || rowIndex === 9 || rowIndex === 10 || rowIndex === 11){
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                } else {
                    return {
                        rowspan: 1,
                        colspan: 1
                    }
                }
            } 
            if (rowIndex === 1 || rowIndex === 12 || rowIndex === 13 || rowIndex === 14 || rowIndex === 15 || rowIndex === 16 || rowIndex === 17 || rowIndex === 18 || rowIndex === 19 || rowIndex === 20 || rowIndex === 22) {
                if (columnIndex === 1) {
                    return [1, 2];
                } else if (columnIndex === 2) {
                    return [0, 0]
                }
            }
        },
        // objectSpanMethod2({ row, column, rowIndex, columnIndex}) {
        //     if (columnIndex === 0) {
        //         if (rowIndex % 5 === 0) {
        //             return {
        //                 rowspan: 5,
        //                 colspan: 1
        //             };
        //         } else {
        //             return {
        //                 rowspan: 0,
        //                 colspan: 0
        //             };
        //         }
        //     }
        // },
        // objectSpanMethod({ row, column, rowIndex, columnIndex}) {
        //     if (columnIndex === 0) {
        //         if (rowIndex % 5 === 0) {
        //             return {
        //                 rowspan: 5,
        //                 colspan: 1
        //             };
        //         } else {
        //             return {
        //                 rowspan: 0,
        //                 colspan: 0
        //             };
        //         }
        //     }
        //     if (rowIndex === 0) {
        //         if (columnIndex === 1) {
        //             return [1, 2];
        //         } else if (columnIndex === 2) {
        //             return [0, 0];
        //         }
        //     }
        // },
        async submitData() {
            this.loading = true
            console.log("gethere")
            let userAnswer = []
            for (let i = 0; i < 16; i++) {
                userAnswer.push([])
            }
            for (let i = 0; i < 24; i++) {
                if (i == 0 || i == 1) {
                    userAnswer[i].push(this.userAnswer[0][i])
                } else if (i == 2 || i == 3 || i == 4 || i == 5 || i == 6) {
                    userAnswer[2].push(this.userAnswer[0][i])
                } else if (i == 7 || i == 8 || i == 9 || i == 10 || i == 11) {
                    userAnswer[3].push(this.userAnswer[0][i])
                } else{
                    userAnswer[i-8].push(this.userAnswer[0][i])
                }
            }
            for (let i = 0; i < 16; i++) {
                if (i == 14) {
                    if (userAnswer[14][0].choiceAnswer === -1) {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                } else if (i == 0 || i == 1 || i == 7 || i == 11 || i == 15) {//HDS-R
                    if (userAnswer[i][0].listAnswer[1] == "") {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                } else if (i == 3 || i == 9 || i == 12 || i == 13) {//MMSE
                    for (let j = 0; j < userAnswer[i].length; j++) {
                        if (userAnswer[i][j].listAnswer[0] == "") {
                            this.$message({
                                message: "アンケートを送信する前に、すべての質問に回答してください。",
                                type: 'warning'
                            })
                            this.loading = false
                            return
                        }
                    }
                } else if (i == 4 || i == 6) {
                    if (userAnswer[i][0].listAnswer[1][0] == "" || userAnswer[i][0].listAnswer[1][1] == "" || userAnswer[i][0].listAnswer[1][2] == "") {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                } else if (i == 8) {
                    if (userAnswer[8][0].listAnswer[0][0] == "" || userAnswer[8][0].listAnswer[0][1] == "") {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                } else if (i == 10) {
                    if (userAnswer[10][0].listAnswer[0][0] == "" || userAnswer[10][0].listAnswer[0][1] == "" || userAnswer[10][0].listAnswer[0][2] == "") {
                        this.$message({
                            message: "アンケートを送信する前に、すべての質問に回答してください。",
                            type: 'warning'
                        })
                        this.loading = false
                        return
                    }
                } else if (i == 2) {
                    for (let j = 0; j < userAnswer[i].length; j++) {
                        if (j == 1){
                            if (userAnswer[2][1].listAnswer[0] == "") {
                                this.$message({
                                    message: "アンケートを送信する前に、すべての質問に回答してください。",
                                    type: 'warning'
                                })
                                this.loading = false
                                return
                            }
                        } else {
                            if (userAnswer[i][j].listAnswer[0] == "" || userAnswer[i][j].listAnswer[1] == "") {
                                this.$message({
                                    message: "アンケートを送信する前に、すべての質問に回答してください。",
                                    type: 'warning'
                                })
                                this.loading = false
                                return
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < userAnswer[i].length; j++) {
                        if (userAnswer[i][j].listAnswer[0] == "" || userAnswer[i][j].listAnswer[1] == "") {
                            this.$message({
                                message: "アンケートを送信する前に、すべての質問に回答してください。",
                                type: 'warning'
                            })
                            this.loading = false
                            return
                        }
                    }
                }  
            }
            try {
                let res = await axios({
                    method: 'post',
                    url: window.serverURL + 'answer',
                    data: {
                        Name: this.name,
                        UserName: this.username,
                        SurveyName: this.questionnaire.title,
                        SurveyType: this.questionnaire.type,
                        SurveyData: userAnswer,
                        PatientName: this.patientName,
                        PatientID: this.patientID,
                    },
                })
                this.$message({
                    message: 'データの送信が成功しました！',
                    type: 'success'
                })
                var value = Object.values(res.data.data)
                var key = Object.keys(res.data.data)
                for (let i = 0; i < key.length; i++) {
                    this.$set(this.$store.state.pdfdata, key[i], value[i])
                }
                this.srcLists[14][0] = this.srcLists[0][22]
                this.srcLists[0][22] = ''
                localStorage.setItem('srcLists', JSON.stringify(this.srcLists))
                localStorage.setItem('userAnswer', JSON.stringify(userAnswer))
                localStorage.setItem('pdfdata', JSON.stringify(res.data.data))
                this.$router.push({path:'/report/' + this.questionnaire.type})
            } catch (e) {
                this.loading = false
                this.$message({
                    message: 'データの送信に失敗しました。もう一度送信してください。',
                    type: 'error'
                })
            }
        },
    },
    async created() {
        this.image = require('@/assets/MMSE+HDS-R/15.01.01.png')
        if (/(iPhone|Android)/i.test(navigator.userAgent)) { //判断iPhone|Android
            this.ifPhone = true
        } else { //pc|ipad
            this.ifPhone = false
        }
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        try {
            let res = await axios.get(window.serverURL + "question?name=MMSE+HDS-R")//development
            this.res = res.data.data
            for (let i = 0; i < this.res.length; i++) {
                this.res[i].options = JSON.parse(this.res[i].options)
                this.$set(this.questionnaire,"id",this.res[0].id)
                this.$set(this.questionnaire,"type",this.res[0].type)
                this.$set(this.questionnaire,"title",this.res[0].title)
                this.$set(this.questionnaire,"mentor",this.res[0].mentor)
                this.$set(this.questionnaire,"mentorJudge",this.res[0].mentorJudge)
                this.questionnaire.groupQuestions = []
                var l = this.res.length
                var grouplength = this.res[l-1].group + 1
                for (let j = 0; j < grouplength; j++ ) {
                    var groupQuestion = {}
                    groupQuestion.group = j
                    for (let t = 0; t < this.res.length; t++) {
                        var g = this.res[t].group
                        if (groupQuestion.group == g) {
                            groupQuestion.title = this.res[t].group_title
                        }
                    }
                    this.questionnaire.groupQuestions.push(groupQuestion)
                    this.questionnaire.groupQuestions[j].messages = []
                    for (let k = 0; k < this.res.length; k++) {
                        if (this.res[k].group == this.questionnaire.groupQuestions[j].group) {
                            var message = {}
                            message.id = this.res[k].question_id
                            message.type = this.res[k].question_type
                            message.content = this.res[k].content
                            if (this.res[k].tableheader.length != 0) {
                                message.headers = JSON.parse(this.res[k].tableheader)
                            }
                            message.supplement = this.res[k].supplement
                            if (this.res[k].options.length != 0) {
                                message.options = this.res[k].options
                            }
                            this.questionnaire.groupQuestions[j].messages.push(message)
                        }
                    }
                }
            }
            this.loading = false
        } catch (e) {
            this.loading = false
            this.$message({
                message: '評価スケール取得に失敗しました。更新して再試行してください。',
                type: 'error'
            })
        }
        // for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
        //     this.srcLists.push([])
        //     this.tableData.push(this.questionnaire.groupQuestions[i].messages)
        //     let listAnswer = []
        //     this.userAnswer.push([])
        //     if (i == 4 || i == 6 || i == 8 || i == 10) {
        //         if (i == 10) {
        //             listAnswer.push([])
        //             listAnswer.push("")
        //             listAnswer[0].push("")
        //             listAnswer[0].push("")
        //             listAnswer[0].push("")
        //         } else if (i == 8) {
        //             listAnswer.push([])
        //             listAnswer.push("")
        //             listAnswer[0].push("")
        //             listAnswer[0].push("")
        //         } else {
        //             listAnswer.push("")
        //             listAnswer.push([])
        //             listAnswer[1].push("")
        //             listAnswer[1].push("")
        //             listAnswer[1].push("")
        //         } 
        //         for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
        //             this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer, tableData: [], time:"", date:"", supplement: ""})
        //         }
        //     } else {
        //         for (let j = 0; j < this.questionnaire.groupQuestions[i].messages.length; j++) {
        //             this.userAnswer[i].push({answerType: this.questionnaire.groupQuestions[i].messages[j].type, choiceAnswer: -1, listAnswer:[], tableData: [], time:"", date:"", supplement: ""})
        //             switch (this.questionnaire.groupQuestions[i].messages[j].type) {
        //                 case "双选择题":
        //                     this.userAnswer[i][j].listAnswer.push("")
        //                     this.userAnswer[i][j].listAnswer.push("")
        //                     break
        //                 default:
        //                     break
        //             }
        //         }
        //     }
        // }
        for (let i = 0; i < this.res.length; i++) {
            let listAnswer = []
            this.srcLists.push([])
            this.userAnswer.push([])
            if (i == 12 || i == 14 || i == 16 || i == 18) {
                if (i == 18) {
                    listAnswer.push([])
                    listAnswer.push("")
                    listAnswer[0].push("")
                    listAnswer[0].push("")
                    listAnswer[0].push("")
                } else if (i == 16) {
                    listAnswer.push([])
                    listAnswer.push("")
                    listAnswer[0].push("")
                    listAnswer[0].push("")
                } else {
                    listAnswer.push("")
                    listAnswer.push([])
                    listAnswer[1].push("")
                    listAnswer[1].push("")
                    listAnswer[1].push("")
                } 
            }else{
                listAnswer.push("")
                listAnswer.push("")
            }
            this.userAnswer[0].push({answerType: this.res[i].question_type, choiceAnswer: -1, listAnswer, tableData: [], time:"", date:"", supplement: ""})
            
        }
        for (let i = 0; i < this.questionnaire.groupQuestions.length; i++) {
            this.tableData.push(this.questionnaire.groupQuestions[i].messages)
        }
        this.$store.commit('getQuestionnarie', this.questionnaire)
        localStorage.setItem('tableData', JSON.stringify(this.tableData))
        localStorage.setItem('questionnaire', JSON.stringify(this.questionnaire))
    },
}
</script>

<style scoped>
@media screen and (max-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 10px;
    }
}
@media screen and (min-width: 700px) {
    .el-form--inline >>> .el-form-item {
        margin-right: 30px;
    }
}
.model{
    margin: auto;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: rgba(10, 10, 10, 0.705);
    display: none;
    z-index: 3000;
}
.model-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #ffffff;
    z-index: 10001;
    font-size: 40px;
}
.demo-form-inline >>> .el-form-item__label {
    padding: 0 5px 0 0;
    font-size: 20px;
}
.formLeft >>> .el-form-item__label {
    font-size: 20px;
}
.formLeft >>> .el-form-item {
    margin-right: 30px;
}
.Table >>> .cell {
    white-space: pre-wrap;
}
.radio >>> .el-radio__label {
    font-size: 18px;
    padding-left: 5px;
}
.radio{
    padding-left: 2px;
    margin-right: 10px;
}
.DrawDialog{
    vertical-align: middle;
    margin: auto;
    width: 100%;
}
.DrawDialog >>> .el-dialog{
    width: 100%;
    height: 100%;
    margin: 0vh;
    margin: 0vh !important
}
.DrawDialog>>> .el-dialog__body{
    margin-top: 0vh; 
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
}
.DrawDialog >>> .el-dialog__header{
    display: none;
    height: 0px;
}
div .el-dialog__wrapper .DrawDialog{
    width: 100%;
    height: 100%;
}
</style> 