<template>
    <div style="margin-top: 4rem;">
        <el-row>
            <el-col>
                <div class="button" style="margin: auto; height: 5rem;">
                    <el-button type="primary" icon="el-icon-arrow-left" @click="toHome" style="text-decoration:none; float: left">戻る</el-button>
                </div>
                <div style="background-color: #ffffff; margin: auto" id="Report">
                    <div style="height: 2rem; font-size: 18px; font-weight: 700; margin-left:auto; margin-right:auto; text-align: center;">{{questionnaire.title}}{{"レポート"}}</div>
                    <el-descriptions :column="4" style="margin: auto;" class="description" size="small">
                        <el-descriptions-item label="氏名">{{pdfdata.basicreport.Name}}</el-descriptions-item>
                        <el-descriptions-item label="診察 ID 番号">{{pdfdata.basicreport.CaseID}}</el-descriptions-item>
                        <el-descriptions-item label="検査者名">{{name}}</el-descriptions-item>
                        <el-descriptions-item label="検査日時">{{pdfdata.basicreport.CreatedAt}}</el-descriptions-item>
                    </el-descriptions>
                    <div v-if="questionnaire.mentor" class="mentor" style="margin-top: 1rem; margin-bottom: 0.3rem; font-size: 12px; font-weight: 400; color: #606266; margin-left: auto; margin-right: auto;">{{questionnaire.mentor}}</div>
                    <div v-if="questionnaire.type ==='GDS'" >
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <el-table
                                class="reslutTable"
                                :data="tableData"
                                border
                                :cell-style="{'padding':'2px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'2px', 'font-weight':'400', 'color':'#000'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column prop="id" width="70" align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '番号'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="220">
                                    <template slot="header">
                                        <p v-for="(text, index) in '質問内容'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="65">
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 0" :label="0">{{groupQuestion.messages[scope.$index].options[0]}}</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="65" label="1点">
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 1" :label="1">{{groupQuestion.messages[scope.$index].options[1]}}</el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center','padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column>
                                   {{"合計"}}
                                </el-table-column>
                                <el-table-column width="65">
                                    {{pdfdata.basicreport.TotalScore}}/15点
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type ==='apathy score'">
                        <el-table
                            class="reslutTable"
                            :data="tableData1"
                            border
                            :cell-style="{'padding':'2px'}"
                            :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                            style="margin-left: auto; margin-right: auto; font-size: 16px">
                            <el-table-column prop="id" width="50" align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in '番号'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column width="230">
                                <template slot="header">
                                    <p v-for="(text, index) in '質問内容'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <p v-for="(text, index) in tableData1[scope.$index].content" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" >
                                <template slot="header">
                                    <p v-for="(text, index) in '大いにある'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer1[scope.$index].choiceAnswer" :disabled="userAnswer1[scope.$index].choiceAnswer != 0" :label="0" >0</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in 'かなりある'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer1[scope.$index].choiceAnswer" :disabled="userAnswer1[scope.$index].choiceAnswer != 1" :label="1" >1</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" >
                                <template slot="header">
                                    <p v-for="(text, index) in '少しある又は少ししかない'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer1[scope.$index].choiceAnswer" :disabled="userAnswer1[scope.$index].choiceAnswer != 2" :label="2" >2</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in '全くない'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer1[scope.$index].choiceAnswer" :disabled="userAnswer1[scope.$index].choiceAnswer != 3" :label="3" >3</el-radio>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                            class="reslutTable"
                            :data="tableData2"
                            border
                            :cell-style="{'padding':'2px'}"
                            :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                            style="margin-left: auto; margin-right: auto; font-size: 16px">
                            <el-table-column prop="id" width="50" align="center"></el-table-column>
                            <el-table-column width="230">
                                <template slot-scope="scope">
                                    <p v-for="(text, index) in tableData2[scope.$index].content" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in '全くそうだ'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer2[scope.$index].choiceAnswer" :disabled="userAnswer2[scope.$index].choiceAnswer != 0" :label="0" >3</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in 'そう思う'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer2[scope.$index].choiceAnswer" :disabled="userAnswer2[scope.$index].choiceAnswer != 1" :label="1" >2</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in 'そうかも知れない'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer2[scope.$index].choiceAnswer" :disabled="userAnswer2[scope.$index].choiceAnswer != 2" :label="2" >1</el-radio>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot="header">
                                    <p v-for="(text, index) in '全くそんなことはない'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                </template>
                                <template slot-scope="scope">
                                    <el-radio size="small" class="radio" v-model="userAnswer2[scope.$index].choiceAnswer" :disabled="userAnswer2[scope.$index].choiceAnswer != 3" :label="3" >0</el-radio>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                            :data="TableData"
                            class="reslutTable"
                            border
                            :show-header="false"
                            :cell-style="{'text-align':'center', 'padding':'2px'}"
                            :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                            style="margin-left: auto; margin-right: auto; font-size: 16px">
                            <el-table-column width="280">
                                {{"合計"}}
                            </el-table-column>
                            <el-table-column >
                                {{pdfdata.basicreport.TotalScore}}/42点
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="questionnaire.type ==='ABS'">
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <el-table
                                class="reslutTable"
                                :data="tableData"
                                border
                                :cell-style="{'padding':'2px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'2px', 'font-weight':'400', 'color':'#000'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column prop="id" width="70" align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '番号'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column  width="200">
                                    <template slot="header">
                                        <p v-for="(text, index) in '質問内容'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in 'ほとんどない'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 0" :label="0" >
                                            {{groupQuestion.messages[scope.$index].options[0]}}
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in 'たまにある'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 1" :label="1" >{{groupQuestion.messages[scope.$index].options[1]}}</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column  align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '時々ある'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 2" :label="2" >{{groupQuestion.messages[scope.$index].options[2]}}</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in 'しょっちゅうある'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 3" :label="3" >{{groupQuestion.messages[scope.$index].options[3]}} </el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center', 'padding':'2px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column width="270">
                                    {{"合計"}}
                                </el-table-column>
                                <el-table-column>
                                    {{pdfdata.basicreport.TotalScore}}/44点
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type === 'ADCS-ADL'">
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <el-table
                                class="reslutTable"
                                :data="tableData"
                                border
                                :cell-style="{'padding':'0px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column prop="id" width="70" align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '番号'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column width="205">
                                    <template slot="header">
                                        <p v-for="(text, index) in '質問内容'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '配点 0'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 0" :label="0" >
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[0]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '配点 1'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 1" :label="1">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[1]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '配点 2'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 2" :label="2">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[2]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '配点 3'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="userAnswer[0][scope.$index].choiceAnswer" :disabled="userAnswer[0][scope.$index].choiceAnswer != 3" :label="3">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[3]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center', 'padding':'2px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 16px">
                                <el-table-column width="275">
                                    {{"合計"}}
                                </el-table-column>
                                <el-table-column>
                                    {{pdfdata.basicreport.TotalScore}}/30点
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type === 'CDR'">
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <el-table
                                class="reslutTable"
                                :data="tableData[group]"
                                :span-method="CDRobjectSpanMethod"
                                border
                                :cell-style="{'padding':'0px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                :show-header="group == 0"
                                style="margin-left: auto; margin-right: auto; font-size: 14px">
                                <el-table-column width="45" align="center">
                                    <template>
                                        <p v-for="(text, index) in groupQuestion.title" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column width="120">
                                    <template slot="header">
                                        <p v-for="(text, index) in '質問内容'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '0（健康）'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 0" :label="0">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[0]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '0.5（疑い）'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 1" :label="1">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[1]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '1（軽度）'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 2" :label="2">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[2]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '2（中等度）'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 3" :label="3">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[3]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot="header">
                                        <p v-for="(text, index) in '3（重度）'" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 4" :label="4">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[4]" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center', 'padding':'2px'}"
                                :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 14px">
                                <el-table-column width="165">
                                    {{"合計"}}
                                </el-table-column>
                                <el-table-column>
                                    {{pdfdata.basicreport.TotalScore}}
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type === 'FAB'">
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <div v-if="group === 0 || group === 1 || group === 3 || group === 4">
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    border
                                    :show-header="group == 0"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px">
                                    <el-table-column width="70" align="center">
                                        {{group+1}}.
                                        <p v-for="(text, index) in groupQuestion.title" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </el-table-column>
                                    <el-table-column min-width="230">
                                        <template slot="header">
                                            <p v-for="(text, index) in '質問内容'" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column min-width="25">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 0" :label="0">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[0]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 1" :label="1">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[1]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 2" :label="2">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[2]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 3" :label="3">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[3]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-else>
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    :span-method="FABobjectSpanMethod"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px">
                                    <el-table-column width="70" align="center">
                                        {{group+1}}.
                                        <p v-for="(text, index) in groupQuestion.title" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </el-table-column>
                                    <el-table-column min-width="230">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column min-width="25">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 0" :label="0">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[0]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 1" :label="1">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[1]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 2" :label="2">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[2]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <el-radio class="smallRadio" v-model="userAnswer[group][scope.$index].choiceAnswer" :disabled="userAnswer[group][scope.$index].choiceAnswer != 3" :label="3">
                                                <p v-for="(text, index) in groupQuestion.messages[scope.$index].options[3]" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </el-radio>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center','padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 14px">
                                <el-table-column>
                                    {{"合計"}}
                                </el-table-column>
                                <el-table-column width="200">
                                    {{pdfdata.basicreport.TotalScore}}/18点
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type === 'MMSE+HDS-R'" >
                        <!-- <el-table
                        :data="res"
                        border
                        :span-method="objectSpanMethod"
                        :max-height=height-150
                        :header-cell-style="{'text-align':'center'}"
                        style="margin-left: auto; margin-right: auto; font-size: 20px; max-width: 100%">
                        <el-table-column width="60" align="center">
                            <template slot-scope="scope">
                                {{res[scope.$index].group+1}}
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot="header">
                                <p v-for="(text, index) in '質問内容'" :key="index">
                                    <span>
                                        {{text}}
                                    </span>
                                </p>
                            </template>
                            <template slot-scope="scope">
                                <p v-for="(text, index) in res[scope.$index].content" :key="index">
                                    <span>
                                        {{text}}
                                    </span>
                                </p>
                                <div v-if="scope.$index == 22">
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="12">
                                            <el-image style="float:left; width: 100px; height: 100px" :src="MMSEImage" fit="contain" :preview-src-list="[image]"></el-image>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="12">
                                            <el-image v-if="srcLists[0][scope.$index]" :src="srcLists[0][scope.$index]" fit="contain" style="float:left; height: 100px" :preview-src-list="[srcLists[0][scope.$index]]"></el-image>
                                        </el-col>
                                    </el-row>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                                <template slot-scope="scope">
                                    <el-row>
                                        <el-col :span="20">
                                            <el-input type="textarea" autosize placeholder="ここに内容を入力してください" v-model="userAnswer[0][scope.$index].supplement" clearable></el-input>
                                        </el-col>
                                        <el-col :span="4">
                                            <span v-if="scope.$index == 0">{{"歳"}}</span>
                                            <span v-if="scope.$index == 2">{{"年"}}</span>
                                            <span v-if="scope.$index == 4">{{"月"}}</span>
                                            <span v-if="scope.$index == 5">{{"日"}}</span>
                                            <span v-if="scope.$index == 6">
                                                <p v-for="(text, index) in '曜日'" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </span>
                                            <span v-if="scope.$index == 7">{{"県"}}</span>
                                            <span v-if="scope.$index == 8">{{"市"}}</span>
                                            <span v-if="scope.$index == 9">
                                                <p v-for="(text, index) in '病院'" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </span>
                                            <span v-if="scope.$index == 10">{{"階"}}</span>
                                            <span v-if="scope.$index == 11">
                                                <p v-for="(text, index) in '地方'" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </span>
                                        </el-col>
                                    </el-row>
                                </template>
                            </el-table-column>
                        <el-table-column  label="MMS">
                            <template slot-scope="scope">
                                <div v-if="scope.$index == 16 || scope.$index == 18">
                                    <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][0]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option11" :key="optionIndex" :label="item.label">
                                            <p v-for="(text, index) in item.label" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][1]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option12" :key="optionIndex" :label="item.label">
                                            <p v-for="(text, index) in item.label" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[0][2]" style="width:100%">
                                        <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1.option13" :key="optionIndex" :label="item.label">
                                            <p v-for="(text, index) in item.label" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                                <el-radio-group v-else v-model="userAnswer[0][scope.$index].listAnswer[0]" style="width:100%">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option1" :key="optionIndex" :label="item.label">
                                        <p v-for="(text, index) in item.label" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </el-radio>
                                </el-radio-group>
                                <div v-if="scope.$index == 12">  
                                    <p v-for="(text, index) in '繰り返した回数'" :key="index">
                                        <span>
                                            {{text}}
                                        </span>
                                    </p>
                                    <br>
                                    （{{userAnswer[0][scope.$index].supplement}}回）
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="HDS-R">
                            <template slot-scope="scope">
                                <div v-if="scope.$index == 12 || scope.$index == 14">
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"a)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][0]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option21" :key="optionIndex" :label="item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"b)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][1]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option22" :key="optionIndex" :label="item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                    <el-row style="white-space: normal;">
                                        <el-col :span="4">
                                            {{"c)"}}
                                        </el-col>
                                        <el-col :span="20">
                                            <el-radio-group v-model="userAnswer[0][scope.$index].listAnswer[1][2]">
                                                <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2.option23" :key="optionIndex" :label="item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </el-col>
                                    </el-row>
                                </div>
                                <el-radio-group v-else v-model="userAnswer[0][scope.$index].listAnswer[1]" style="width:100%">
                                    <el-radio class="radio" v-for="(item, optionIndex) in res[scope.$index].options.option2" :key="optionIndex" :label="item.label">
                                        <p v-for="(text, index) in item.label" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                    </el-radio>
                                </el-radio-group>
                            </template>
                        </el-table-column>
                    </el-table> -->
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <!-- 需要有输入框 -->
                            <div v-if="group === 0 || group === 2 || group === 3 || group === 15 || group === 13">
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    :span-method="MMSObjectSpanMethod2"
                                    border
                                    :show-header="group == 0"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="50" align="center">
                                        <template>
                                            {{group+1}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column min-width="220">
                                        <template slot="header">
                                            <p v-for="(text, index) in '質問内容'" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in questionnaire.groupQuestions[group].messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column>
                                        <template slot-scope="scope">
                                            <el-row>
                                                <el-col :span="16">
                                                    <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-col>
                                                <el-col :span="8">
                                                    <span v-if="group == 0">{{"歳"}}</span>
                                                    <span v-if="group == 2 && scope.$index == 0">{{"年"}}</span>
                                                    <span v-if="group == 2 && scope.$index == 2">{{"月"}}</span>
                                                    <span v-if="group == 2 && scope.$index == 3">{{"日"}}</span>
                                                    <span v-if="group == 2 && scope.$index == 4">
                                                        <p v-for="(text, index) in '曜日'" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </span>
                                                    <span v-if="group == 3 && scope.$index == 0">{{"県"}}</span>
                                                    <span v-if="group == 3 && scope.$index == 1">{{"市"}}</span>
                                                    <span v-if="group == 3 && scope.$index == 2">
                                                        <p v-for="(text, index) in '病院'" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </span>
                                                    <span v-if="group == 3 && scope.$index == 3">{{"階"}}</span>
                                                    <span v-if="group == 3 && scope.$index == 4">
                                                        <p v-for="(text, index) in '地方'" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </span>
                                                </el-col>
                                            </el-row>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="MMS" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width:100%">
                                                <div v-if="groupQuestion.messages[scope.$index].options.option1">
                                                    <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0] != item.label">
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </div>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="HDS-R" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1]" style="width:100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[1] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- HDS-R有多个选项框 -->
                            <div v-else-if="group === 4 || group === 6">
                                <el-table
                                    class="reslutTable"
                                    :span-method="MMSObjectSpanMethod"
                                    :data="tableData[group]"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="50" align="center">
                                        {{group+1}}
                                    </el-table-column>
                                    <el-table-column min-width="220">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column>
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="MMS" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width: 100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                            <div v-if="group == 4">  
                                                <p v-for="(text, index) in '繰り返した回数'" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                               <br>
                                                （{{userAnswer[group][scope.$index].supplement}}回）
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="HDS-R" width="120">
                                        <template slot-scope="scope">
                                            <el-row style="white-space: normal;">
                                                <el-col :span="4">
                                                    {{"a)"}}
                                                </el-col>
                                                <el-col :span="20">
                                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][0]">
                                                        <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option21" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[1][0] != item.label">
                                                            <p v-for="(text, index) in item.label" :key="index">
                                                                <span>
                                                                    {{text}}
                                                                </span>
                                                            </p>
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-col>
                                            </el-row>
                                            <el-row style="white-space: normal;">
                                                <el-col :span="4">
                                                    {{"b)"}}
                                                </el-col>
                                                <el-col :span="20">
                                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][1]">
                                                        <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option22" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[1][1] != item.label">
                                                            <p v-for="(text, index) in item.label" :key="index">
                                                                <span>
                                                                    {{text}}
                                                                </span>
                                                            </p>
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-col>
                                            </el-row>
                                            <el-row style="white-space: normal;">
                                                <el-col :span="4">
                                                    {{"c)"}}
                                                </el-col>
                                                <el-col :span="20">
                                                    <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1][2]">
                                                        <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2.option23" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[1][2] != item.label">
                                                            <p v-for="(text, index) in item.label" :key="index">
                                                                <span>
                                                                    {{text}}
                                                                </span>
                                                            </p>
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-col>
                                            </el-row>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- MMS有多个选项框 -->
                            <div v-else-if="group === 8 || group === 10">
                                <el-table
                                    class="reslutTable"
                                    :span-method="MMSObjectSpanMethod"
                                    :data="tableData[group]"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="50" align="center">
                                        <template>
                                            {{group+1}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column min-width="220">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column>
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="MMS" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][0]" style="width:100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option11" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0][0] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][1]" style="width:100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option12" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0][1] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0][2]" style="width:100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1.option13" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0][2] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="HDS-R" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]" style="width:100%">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            
                            <div v-else>
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    :span-method="MMSObjectSpanMethod"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="50" align="center">
                                        <template>
                                            {{group+1}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column min-width="220">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                            <div v-if="groupQuestion.messages[scope.$index].type === '画图题'">
                                                <el-row>
                                                    <el-col :xs="24" :sm="12" :md="12">
                                                        <el-image style="float:left; width: 100px; height: 100px" :src="MMSEImage" fit="contain" :preview-src-list="[image]"></el-image>
                                                    </el-col>
                                                    <el-col :xs="24" :sm="12" :md="12">
                                                        <el-image v-if="srcLists[group][scope.$index]" :src="srcLists[group][scope.$index]" fit="contain" style="float:left; height: 100px" :preview-src-list="[srcLists[group][scope.$index]]"></el-image>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column>
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="MMS" width="120">
                                        <template slot-scope="scope">
                                            <div v-if="groupQuestion.messages[scope.$index].type === '画图题'">
                                                <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                    <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].choiceAnswer != item.label">
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div v-else>
                                                <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[0]">
                                                    <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[0] != item.label">
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </el-radio-group>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="HDS-R" width="120">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].listAnswer[1]">
                                                <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="item.label" :disabled="userAnswer[group][scope.$index].listAnswer[1] != item.label">
                                                    <p v-for="(text, index) in item.label" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center','padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 14px">
                                <el-table-column>
                                    {{"合計"}}
                                </el-table-column>
                                <el-table-column  width="120">
                                    {{pdfdata.TableData[0]}}/30
                                </el-table-column>
                                <el-table-column  width="120">
                                    {{pdfdata.TableData[1]}}/30
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="questionnaire.type === 'MoCA'">
                        <div v-for="(groupQuestion, group) in questionnaire.groupQuestions" :key="group">
                            <div v-if="group == 0">
                                <el-table
                                    class="reslutTable"
                                    :data="TableData"
                                    border
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="75" align="center">
                                        {{"1."}}
                                        <p v-for="(text, index) in questionnaire.groupQuestions[0].title" :key="index">
                                            <span v-if="text==' '">&nbsp;&nbsp;</span>
                                            <span v-else>
                                                {{text}}
                                            </span>
                                        </p>
                                    </el-table-column>
                                    <el-table-column label="a) Trail-making">
                                        <p v-for="(text, index) in groupQuestion.messages[0].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                        <div>
                                            <el-image v-if="srcLists[0][0]" style="margin-left: auto; margin-right: auto; width: 120px" :src="srcLists[0][0]" fit="contain" :preview-src-list="[srcLists[0][0]]"></el-image>
                                            <div v-else>
                                                <el-image v-for="(image, index) in questionnaire.groupQuestions[0].messages[0].imageArray" :key="index" style="margin-left: auto; margin-right: auto; width: 120px" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                            </div>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  label="b) 図形模写">
                                        <p v-for="(text, index) in groupQuestion.messages[1].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                        <el-row>
                                            <el-col :span="24">
                                                <el-image v-for="(image, index) in questionnaire.groupQuestions[0].messages[1].imageArray" :key="index" style="margin-left: auto; margin-right: auto; width: 60px" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                            </el-col>
                                            <el-col :span="24">
                                                <el-image v-if="srcLists[0][1]" style="margin-left: auto; margin-right: auto; width: 100px" :src="srcLists[0][1]" fit="contain" :preview-src-list="[srcLists[0][1]]"></el-image>
                                            </el-col>
                                        </el-row>
                                    </el-table-column>
                                    <el-table-column label="c) 時計描画">
                                        <p v-for="(text, index) in groupQuestion.messages[2].content" :key="index">
                                            <span>
                                                {{text}}
                                            </span>
                                        </p>
                                        <div>
                                            <el-image v-if="srcLists[0][2]" style="margin-left: auto; margin-right: auto; width: 100px" :src="srcLists[0][2]" fit="contain" :preview-src-list="[srcLists[0][2]]"></el-image>
                                        </div>
                                    </el-table-column>
                                    <el-table-column label="得点" width="130">
                                    <div v-for="(message, index) in questionnaire.groupQuestions[0].messages" :key="index">
                                        <el-row style="white-space: normal;">
                                            <el-col :span="4">
                                                <span v-if="index == 0">{{"a)"}}</span>
                                                <span v-if="index == 1">{{"b)"}}</span>
                                                <span v-if="index == 2">{{"c)"}}</span>
                                            </el-col>
                                            <el-col :span="20">
                                                <el-radio-group v-model="userAnswer[group][index].choiceAnswer">
                                                    <el-radio  class="MMSRadio" v-for="(item, optionIndex) in message.options.option" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][index].choiceAnswer != optionIndex">
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </el-radio-group>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-if="group == 3">
                                <el-table
                                    class="reslutTable"
                                    :span-method="MoCAobjectSpanMethod"
                                    :data="tableData[group]"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="75" align="center">
                                        <span class="groupTitle">
                                            {{group+1}}.
                                            <p v-for="(text, index) in groupQuestion.title" :key="index">
                                                <span v-if="text==' '">&nbsp;&nbsp;</span>
                                                <span v-else>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </span>
                                    </el-table-column>
                                    <el-table-column min-width="250">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="得点" width="130">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                <el-radio class="MMSRadio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][scope.$index].choiceAnswer != optionIndex">
                                                    <p v-for="(text, index) in option" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-else-if="group == 4">
                                <el-table
                                    class="reslutTable"
                                    :span-method="MoCAobjectSpanMethod1"
                                    :data="tableData[group]"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="75" align="center">
                                        <span class="groupTitle">
                                            {{group+1}}.
                                            <p v-for="(text, index) in groupQuestion.title" :key="index">
                                                <span v-if="text==' '">&nbsp;&nbsp;</span>
                                                <span v-else>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </span>
                                    </el-table-column>
                                    <el-table-column min-width="250">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                            <div v-if="scope.$index === 1">
                                                <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="得点" width="130">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                <el-radio class="MMSRadio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][scope.$index].choiceAnswer != optionIndex">
                                                    <p v-for="(text, index) in option" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- 表格有三列数据 -->
                            <div v-else-if="group == 1 || group == 2 || group == 5 || group == 6 || group == 7 ">
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    border
                                    :show-header="group == 0"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="75">
                                        <span class="groupTitle" align="center">
                                            {{group+1}}.
                                            <p v-for="(text, index) in groupQuestion.title" :key="index">
                                                <span v-if="text==' '">&nbsp;&nbsp;</span>
                                                <span v-else>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </span>
                                    </el-table-column>
                                    <el-table-column min-width="250">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                            <div v-if="group === 1">
                                                <el-image v-for="(image, index) in groupQuestion.messages[scope.$index].imageArray" :key="index" style="margin-left: auto; margin-right: auto; width: 120px" :src="image" fit="contain" :preview-src-list="[image]"></el-image>
                                            </div>
                                            <div v-if="group === 2">
                                                <el-row style="white-space: normal;">
                                                    <el-col :span="6">
                                                        {{groupQuestion.messages[scope.$index].options.optionTitle[0]}}
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer[0]">
                                                            <el-checkbox class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :label="item.label" :key="optionIndex" disabled>
                                                                <p v-for="(text, index) in item.label" :key="index">
                                                                    <span>
                                                                        {{text}}
                                                                    </span>
                                                                </p>
                                                            </el-checkbox>
                                                        </el-checkbox-group>
                                                    </el-col>
                                                </el-row>
                                                <el-row style="white-space: normal;">
                                                    <el-col :span="6">
                                                        {{groupQuestion.messages[scope.$index].options.optionTitle[1]}}
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer[1]">
                                                            <el-checkbox class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :label="item.label" :key="optionIndex" disabled>
                                                                <p v-for="(text, index) in item.label" :key="index">
                                                                    <span>
                                                                        {{text}}
                                                                    </span>
                                                                </p>
                                                            </el-checkbox>
                                                        </el-checkbox-group>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                            <div v-if="group === 6">
                                                <el-checkbox-group v-model="userAnswer[group][scope.$index].listAnswer">
                                                    <el-checkbox class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option1" :label="optionIndex" :key="optionIndex" disabled >
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-checkbox>
                                                </el-checkbox-group>
                                            </div>
                                            <div v-if="group === 7">
                                                <p v-for="(text, index) in userAnswer[group][scope.$index].supplement" :key="index">
                                                    <span>
                                                        {{text}}
                                                    </span>
                                                </p>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="得点" width="130">
                                        <template slot-scope="scope">
                                            <div v-if="group == 6">
                                                <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                    <el-radio class="MMSRadio" v-for="(item, optionIndex) in groupQuestion.messages[scope.$index].options.option2" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][scope.$index].choiceAnswer != optionIndex">
                                                        <p v-for="(text, index) in item.label" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div v-if="group == 1 || group == 5 || group == 7">
                                                <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                    <el-radio class="MMSRadio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][scope.$index].choiceAnswer != optionIndex">
                                                        <p v-for="(text, index) in option" :key="index">
                                                            <span>
                                                                {{text}}
                                                            </span>
                                                        </p>
                                                    </el-radio>
                                                </el-radio-group>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-if="group == 8">
                                <el-table
                                    class="reslutTable"
                                    :data="tableData[group]"
                                    border
                                    :show-header="false"
                                    :cell-style="{'padding':'0px'}"
                                    :header-cell-style="{'text-align':'center', 'padding':'0px', 'font-weight':'400', 'color':'#000'}"
                                    style="margin-left: auto; margin-right: auto; font-size: 14px; max-width: 100%">
                                    <el-table-column width="75" align="center">
                                    </el-table-column>
                                    <el-table-column min-width="250">
                                        <template slot-scope="scope">
                                            <p v-for="(text, index) in groupQuestion.messages[scope.$index].content" :key="index">
                                                <span>
                                                    {{text}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="130">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="userAnswer[group][scope.$index].choiceAnswer">
                                                <el-radio class="MMSRadio" v-for="(option, optionIndex) in groupQuestion.messages[scope.$index].options" :key="optionIndex" :label="optionIndex" :disabled="userAnswer[group][scope.$index].choiceAnswer != optionIndex">
                                                    <p v-for="(text, index) in option" :key="index">
                                                        <span>
                                                            {{text}}
                                                        </span>
                                                    </p>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div>
                            <el-table
                                :data="TableData"
                                class="reslutTable"
                                border
                                :show-header="false"
                                :cell-style="{'text-align':'center','padding':'0px'}"
                                style="margin-left: auto; margin-right: auto; font-size: 14px">
                                <el-table-column>
                                   {{"合計得点"}}
                                </el-table-column>
                                <el-table-column width="130">
                                    {{pdfdata.basicreport.TotalScore}}/30点
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <el-button @click="test" type="primary" style="margin-top: 3rem; margin-bottom: 3rem;">レポートをダウンロード</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import axios from 'axios'
    import html2pdf from 'html2pdf.js'
    export default {
        data() {
            return {
                name: '',
                tableData1: [],
                tableData2: [],
                userAnswer1: [],
                userAnswer2: [],
                tableData: [],
                userAnswer: [],
                srcLists: [],
                questionnaire: {},
                pdfdata: {},
                TableData: [{}],
                devicePixelRatio: '',
                MMSEImage: '',
            }
        },
        methods: {
            MoCAobjectSpanMethod({ row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    if (rowIndex === 0) {
                        return {
                            rowspan: 3,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            MoCAobjectSpanMethod1({ row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    if (rowIndex === 0) {
                        return {
                            rowspan: 2,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            MoCAObjectSpanMethod3({ row, column, rowIndex, columnIndex}) {
                if (rowIndex === 0) {
                    if (columnIndex === 0) {
                        return [1, 2];
                    } else if (columnIndex === 1) {
                        return [0, 0];
                    }
                }
            },
            FABobjectSpanMethod({ row, column, rowIndex, columnIndex}) {
                if (rowIndex === 0) {
                    if (columnIndex === 1) {
                        return [1, 2];
                    } else if (columnIndex === 2) {
                        return [0, 0];
                    }
                }
            },
            MMSObjectSpanMethod2({ row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    if (rowIndex % 5 === 0) {
                        return {
                            rowspan: 5,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            MMSObjectSpanMethod({ row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    if (rowIndex % 5 === 0) {
                        return {
                            rowspan: 5,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (rowIndex === 0) {
                    if (columnIndex === 1) {
                        return [1, 2];
                    } else if (columnIndex === 2) {
                        return [0, 0];
                    }
                }
            },
            MMSObjectSpanMethod3({ row, column, rowIndex, columnIndex}) {
                if (rowIndex === 0) {
                    if (columnIndex === 0) {
                        return [1, 3];
                    } else if (columnIndex === 1 || columnIndex === 2 ) {
                        return [0, 0];
                    }
                }
            },
            CDRobjectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (rowIndex % 5 === 0) {
                        return {
                            rowspan: 5,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (rowIndex === 4) {
                    if (columnIndex === 2) {
                        return [1, 2];
                    } else if (columnIndex === 3){
                        return [0, 0];
                    }
                }
            },
            toHome() {
                this.$router.push({path: '/questionnaire'})
            },
            test() {
                var ratio = 0
                if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
                    if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                        ratio = window.outerWidth / window.innerWidth;
                    }
                } else { //判断PC Android和 linux（比如nest hub max）
                    if (window.devicePixelRatio == this.devicePixelRatio) {
                        ratio = 1
                    }
                }
                if (ratio == 1) {
                    var width = document.body.clientWidth
                    var fileName = this.questionnaire.title + 'レポート.pdf'
                    var element = document.getElementById('Report');
                    var opt = {
                        margin:       [0.5, 0, 0.1, 0],
                        filename: fileName,
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2, scrollX: 0, scrollY: 0, windowWidth: width, letterRendering: true},
                        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                        pagebreak:    { mode: [ 'avoid-all' , 'css' ,  'legacy' ]}
                    };
                    html2pdf().set(opt).from(element).save();
                } else {
                    this.$message({
                        message: 'ページをスケーリングするとダウンロードファイルのフォーマットエラーが発生しますので、ブラウザの元のスケーリングに戻してからダウンロードしてください。',
                        type: 'warning'
                    });
                }
            },
        },
    created() {
        this.MMSEImage = require('@/assets/MMSE+HDS-R/15.01.01.png')
        this.devicePixelRatio = window.devicePixelRatio
        if (this.$route.path !== '/login') {
            this.loading = true
            axios.get(window.serverURL + 'verify')
                .then(response => {
                    if (response.status === 200) {
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$store.commit('needLogin')
                })
                .catch(error => {
                    this.loading = false
                    this.$router.push('/login')
                })
        }
        this.questionnaire = JSON.parse(localStorage.getItem('questionnaire'))
        this.tableData = JSON.parse(localStorage.getItem('tableData'))
        this.userAnswer = JSON.parse(localStorage.getItem('userAnswer'))
        this.pdfdata = JSON.parse(localStorage.getItem('pdfdata'))
        this.pdfdata.basicreport.CreatedAt = this.pdfdata.basicreport.CreatedAt.substring(0, 10).replace("T", " ")
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        if (this.questionnaire.type === "MoCA" || this.questionnaire.type === "MMSE+HDS-R"){
            if (localStorage.getItem('srcLists').length != 0) {
                this.srcLists = JSON.parse(localStorage.getItem('srcLists'))
            }
        }
        if(this.questionnaire.type === "apathy score") {
            for (let i = 0; i < 8; i++) {
                this.tableData1.push(this.questionnaire.groupQuestions[0].messages[i])
                this.userAnswer1.push(this.userAnswer[0][i])
            }
            for (let i = 8; i < 14; i++) {
                this.tableData2.push(this.questionnaire.groupQuestions[0].messages[i])
                this.userAnswer2.push(this.userAnswer[0][i])
            }
        }
        this.loading = false
        if (this.questionnaire.mentorJudge === true) {
            this.dialogVisible = true
        }
    }
}
// window.onload = function() {
//     document.documentElement.addEventListener('touchstart', function (event) {
//             if (event.touches.length > 1) {
//                 event.preventDefault(); 
//             }
//     }, {passive: false, capture: false}); 
//         // 禁止双击放大 
//         let lastTouchEnd = 0; 
//         document.documentElement.addEventListener('touchend', function (event) { 
//             var now = Date.now(); 
//             if (now - lastTouchEnd <= 300) { 
//                 event.preventDefault(); 
//             } lastTouchEnd = now; 
//         }, {passive:false});
//     // 阻止双指放大
//     document.documentElement.addEventListener('gesturestart', function(event) {
//         event.preventDefault();
//         alert("请勿缩放")
//     });
//     document.documentElement.addEventListener('dblclick', function (event) {
//         event.preventDefault();
// 	})
// }
</script>

<style scoped>
p{
    margin-block-start:0rem;
    margin-block-end: 0em;
    display: inline-block;
    letter-spacing: 1px;
}
.description >>> .el-descriptions__body table td, .el-descriptions__body table th {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;
    font-weight: 400;
    line-height: 0.5;
}
.tableBox{
    text-align: center;
}
.groupTitle{
    word-break: normal;
    white-space: pre-line;
}
.radio >>> .el-radio__label {
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
    padding-left: 0px;
    padding-top: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
.radio >>> .el-radio__inner{
    display: none;
}
.smallRadio >>> .el-radio__label {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
    padding-left: 0px;
    padding-top: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
.smallRadio >>> .el-radio__inner{
    display: none;
}
.MMSRadio >>> .el-radio__label {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1px;
    vertical-align: middle;
    display: inline-block;
    padding-top: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-bottom: 0px;
}
.MMSRadio{
    padding-left: 2px;
    margin-right: 10px;
}
.MMSRadio >>> .el-radio__inner{
    display: none;
}
.el-table{
    color: #000;
}
.el-table >>> .cell {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    margin-top: 0px;
    height: 100%;
}
    .title {
        color: #222222;
        font-weight: 600;
    }
    .notes {
        font-size: 10px;
    }
    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 24px;
        margin-left: 0px;
    }
    @media screen and (min-width: 640px) {
    .button{
        width: 610px;
    }
    .reslutTable{
        width: 610px;
    }
    .description{
        max-width: 610px;
    }
    .mentor{
        width: 610px;
    }
    }
    @media screen and (min-width: 1100px) {
    .button{
        width: 660px;
    }
    .reslutTable{
        width: 660px;
    }
    .description{
        max-width: 660px;
    }
    .mentor{
        width: 660px;
    }
    }
</style>